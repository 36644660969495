import { APICallOptions, APIEntity, apiGet, apiPost } from '$core/services/api.service'
import { get } from 'svelte/store'
import { getPreferenceByKind, savePreference } from '$shared/services/preference.service'
import { WorkspaceStore } from '$crm/stores/workspace.store'
import { DunningMessageKind, type DunningMessageTemplateItem, type DunningMessageTemplatePrefKey } from '../models/dunning-message-template'
import type { PersistedPreference } from '$shared/models/preference.model'
import { v4 as uuidv4 } from 'uuid'
import { ContactsStore } from '$crm/stores/contacts.store'
import { stringNormalize } from '$src/shared/utils/string'
import { remoteDebuggingService } from '$src/core-app/services/remote-debugging.service'
import { validateEmail } from '$src/core-app/services/validator'
import { placeholders } from '../data/placeholder'
import { getEmailWrapper, type FieldsErrors, type FormField } from '../data/template'
import { substTiphaine } from '$src/voxy-app/services/specialOptalis/tiphaine.service'
import { ProfileStore } from '$src/core-app/stores/profile.store'
import { t } from '$src/core-app/lib/i18n/i18nextWrapper'
import type { Contact } from '$src/crm-app/models/contact'
import { generatePaymentLinkForInvoiceAndCustomer, populateEmailHeader } from './receivable-action.service'
import type { SendGridEmailRecipient } from '../models/sendgrid-email-configuration'
import { DunningInvoicesStore } from '../stores/dunning-invoices.store'
import Invoice from '../models/invoice'
import { CustomersStore } from '$src/crm-app/stores/customers.store'
import type { Customer } from '$src/crm-app/models/customer'

type DunningTemplateResponse = {
  err: any[]
  prefKey: DunningMessageTemplatePrefKey | null;
  prefValue: DunningMessageTemplateItem | null;
  prefValueDefault: DunningMessageTemplateItem | null;
}

/**
 * GET CONTACTS FOR
 * @param {string}  customerId
 * @param {string} media 
 * @returns 
 */
export const getContacts = (customerId:string, media: string) => get(ContactsStore).reduce((acc:any[], cur:Contact) => {
  const isCustomerContact = customerId && cur.companyId === customerId
  const isTeamUserButNotOwner = get(WorkspaceStore).workspaceId === cur.companyId && cur.isWorkspaceMember

  if (isCustomerContact || isTeamUserButNotOwner) {

    if (media === DunningMessageKind.DunningEmail) {
      if (cur.email) acc.push({ key: cur.email, value: `${cur.firstName} ${cur.lastName} (${cur.email})` })
    } else if (media === DunningMessageKind.DunningPhoneCall) {
      if (cur.mobilePhone && cur.officePhone) {
        acc.push({ key: cur.mobilePhone, value: `${cur.firstName} ${cur.lastName} (${cur.mobilePhone} - ${cur.officePhone})` })
      } else {
        if (cur.mobilePhone) acc.push({ key: cur.mobilePhone, value: `${cur.firstName} ${cur.lastName} (${cur.mobilePhone})` })

        if (cur.officePhone) acc.push({ key: cur.officePhone, value: `${cur.firstName} ${cur.lastName} (${cur.officePhone})` })
      }
    } else if (media === DunningMessageKind.DunningPostalLetter) {
      if (cur.email) acc.push({ key: cur.email, value: `${cur.firstName} ${cur.lastName} (${cur.email})` })
    }
  }
  
  return acc
}, [])


/**
 * CONVERT RAW TEXT (from db with \n) TO HTML (to UI with <div>)
 * @param {string} text 
 * @returns {string}
 */
export const convertRawToHtml = (text: string): string => {
  if (!text) return text

  const regex = /[^\n]*\n?/g
  const sentences = text.match(regex)
  if (sentences) return sentences.map((p: string) => {
    const trimmedSentence = p.trim()

    return trimmedSentence ? `<div>${trimmedSentence}</div>` : '<div><br></div>'
  }).slice(0, -1).join('')

  return text
}

/**
 * CONVERT HTML (from UI with <div>) TO RAW TEXT (to db with \n)
 * @param {string} text 
 * @returns {string}
 */
export const convertHtmlToRaw = (text: string): string => {
  if (!text) return text

  return text
    .replace(/<div><br><\/div>/g, '\n')
    .replace(/<(div|p|span).*?>/g, '')
    .replace(/<\/(div|p|span)>/g, '\n')
}

/**
 * CONVERT EDITOR VALUE (string/object) TO RAW DATA
 * @param {string | object} data 
 * @returns {} | undefined
 */
const convertEditorToData = (data: any) => {
  if (typeof data === 'string') return convertHtmlToRaw(data)

  if (typeof data === 'object') {
    return Object.entries(data).reduce((acc:any, [key, value]) => {
      acc[key] = convertHtmlToRaw(value as string)

      return acc
    }, {})
  }
}

/**
 * REPLACE PLACEHOLDER FROM TEXT WITH BUSINESS VALUES
 * @param {string} text 
 * @returns {string}
 */
export const replacePlaceholders = (text :string, invoiceId?:string, customData?:{ [key:string]:string }): string => {
  if (!text) return ''

  // Get data for placeolders.fn()
  const profileStore = get(ProfileStore)
  const workspaceStore = get(WorkspaceStore)
  const invoice = get(DunningInvoicesStore).find(i => i.dataId === invoiceId) || new Invoice()
  
  const data = {
    profile: profileStore,
    bankConfig: workspaceStore.bankConfig,
    company: workspaceStore.company,
    contacts: workspaceStore.contacts,
    customers: workspaceStore.customers,
    invoice,
    ...customData
  }
  
  // extract placeholders from text
  const replacements:string = placeholders.map(p => p.key).join('|')
  const pattern = new RegExp(`(${replacements})`, 'gi')

  return text.replace(pattern, (match: string, key: string) => {
    // get primary placeholder
    const placeholder = placeholders.find(p => p.key === key)
    if (!placeholder) return match

    let newText = placeholder.fn(data) ?? ''

    // if placeholder contains other placeholders, replace them
    if (newText) newText = newText.replace(pattern, (_: string, k: string) => placeholders.find(p => p.key === k)?.fn(data) ?? '')
    
    return newText
  })
}

/**
 * RETRIEVE DUNNNING TEMPLATE FROM WORKFLOW AND STEP
 * @param {string} workflowKind 
 * @param {string} workflowStep 
 * @returns 
 */
export const getDunningTemplate = async (workflowKind: DunningMessageKind, workflowStep: string, customerId?:string): Promise<DunningTemplateResponse> => {
  const workspaceId: string = get(WorkspaceStore).workspaceId

  const payload = {
    workspaceId: workspaceId,
    workflowStep: {
      workflowKind,
      workflowStep
    }
  }

  if (customerId) payload.customerId = customerId

  const res = await getPreferenceByKind(workspaceId, 'DunningMessageTemplate', [payload])

  let err: any[] = res?.failures
  let prefKey: DunningMessageTemplatePrefKey | null = null
  let prefValue: DunningMessageTemplateItem | null = null
  let prefValueDefault: DunningMessageTemplateItem | null = null

  if (!err.length) {
    const tmp = res?.successes[0]

    let hasErr: boolean = false
    if (!tmp?.sortedDunningMessageTemplatePrefs) {
      err.push('')
      hasErr = true
    }
    if (!tmp?.sortedDunningMessageTemplateItems) {
      err.push('')
      hasErr = true
    }

    if (!hasErr) {
      // system default value for input placeholder
      prefValueDefault = tmp.sortedDunningMessageTemplateItems?.find((i: any) => i.isSystemDefault) ?? null

      // current template for edition
      const prf = tmp.sortedDunningMessageTemplatePrefs?.at(0)

      if (prf) {
        prefKey = prf?.prefKey
        if (!prefKey) {
          err.push('')
        } else {
          prefValue = tmp.sortedDunningMessageTemplateItems.find((p: any) => p.templateItemRef === prf.templateItemRef)
          if (!prefValue) {
            err.push('')
          } else {
            prefValue.workspaceId = workspaceId
          }
        }
      }
    }
  }

  return {
    err,
    prefKey,
    prefValue,
    prefValueDefault
  }
}

type TemplateItemResponse = {
  successs: any[],
  failures: any[]
}

/**
 * SAVE DunningMessageTemplateItem FOR A WORKSPACE
 * @param worskpaceId
 * @param templateItem
 * @returns
 */
const saveDunningMessageTemplateItem = (
  worskpaceId: string,
  templateItem: DunningMessageTemplateItem
): Promise<TemplateItemResponse> =>
  apiPost(`/workspace/${worskpaceId}/dunning-message-template-items`, [templateItem], <APICallOptions>{
    entity: APIEntity.TOTUM_PREFERENCE,
    ignoreFeedback: true
  })


/**
 * SPLIT STRING INPUT CONTACT TO ARRAY STRING CONTACT
 * @param value 
 * @returns 
 */
const splitContactString = (value: string) => {
  const separators = /[,; ]+/
  
  return value.split(separators)
}

/**
 * CONVERT FORM INPUTS TO PAYLOAD VALUES : CONTACT / LITERAL / PLACEHOLDER
 * @param value 
 * @returns 
 */
const stringToValue = (value: string, kind: DunningMessageKind) => {
  if (typeof value === 'boolean') return value

  const arr:string[] = splitContactString(value)
  if (!arr) return null

  const contacts: any[] = []

  arr.forEach((v: string) => {
    if (!v) return
    // placeholder
    if (v.startsWith('{') && v.endsWith('}')) {
      contacts.push({ dunningPlaceHolder: v })
    } else {
      // contact
      const ctt = get(ContactsStore).find(c => {
        if (kind === DunningMessageKind.DunningEmail) {
          return c.email === v
        } else if (kind === DunningMessageKind.DunningPhoneCall) {
          return c.mobilePhone === v || c.officePhone === v
        } else if (kind === DunningMessageKind.DunningPostalLetter) {
          // TODO : a vérifier avec Franck... en fonction du kind
          return c.firstName + ' ' + c.lastName === v
        }
      })
      if (ctt) {
        contacts.push({ contactReference: ctt.contactId })
      } else {
        // literal
        contacts.push({ literalEmail: v }) // TODO : a vérifier avec Franck... en fonction du kind
      }
    }
  })

  return contacts.length ? contacts : null
}

const decodeHtml = (text: string): string => {
  const textArea = document.createElement('textarea')
  textArea.innerHTML = text

  return textArea.value
}

const checkMails = (mails: string[]): string => {
  if (!mails) return ''

  const err = mails.reduce((acc: string[], cur: any) => {
    if (cur.literalEmail && !validateEmail(cur.literalEmail)) {
      acc.push(cur.literalEmail)
    } else if (cur.dunningPlaceHolder && !placeholders.find(p => p.key === cur.dunningPlaceHolder)) {
      acc.push(cur.dunningPlaceHolder)
    }

    return acc
  }, [])

  return err?.map((m: any) => decodeHtml(m))?.join(', ')
}


/**
 * 
 * @param kind 
 * @param template 
 * @returns 
 */
const checkTemplate = (kind: string, template: any): null | FieldsErrors => {
  let errors: FieldsErrors = {}

  let mails

  switch (kind) {
    case 'DunningEmail':
      if (!template.dunningEmail?.emailFrom) {
        errors.emailFrom = t('template.errors.missingMail')
      } else {
        if (template.dunningEmail?.emailFrom?.length > 1) errors.emailFrom = t('template.errors.onlyOneMail')

        mails = checkMails(template.dunningEmail.emailFrom)
        if (mails) errors.emailFrom = t('template.errors.badValues', { error: mails })
      }
      if (!template.dunningEmail?.emailsTo) {
        errors.emailsTo = t('template.errors.missingMail')
      } else {
        mails = checkMails(template.dunningEmail.emailsTo)
        if (mails) errors.emailsTo = t('template.errors.badValues', { error: mails })
      }

      mails = checkMails(template.dunningEmail.emailsCC)
      if (mails) errors.emailsCC = t('template.errors.badValues', { error: mails })

      mails = checkMails(template.dunningEmail.emailsCCI)
      if (mails) errors.emailsCCI = t('template.errors.badValues', { error: mails })

      if (!template.dunningEmail.subject) errors.subject = t('template.errors.emptyField')
      if (!template.dunningEmail.body) errors.body = t('template.errors.emptyField')
      break

    case 'DunningPostalLetter':
      if (!template.dunningPostalLetter?.contactDestination) errors.contactDestination = t('template.errors.emptyField')
      if (!template.dunningPostalLetter?.letterSubject) errors.letterSubject = t('template.errors.emptyField')
      if (!template.dunningPostalLetter?.letterBody) errors.letterBody = t('template.errors.emptyField')
      break

    case 'DunningPhoneCall':
      if (!template.dunningPhoneCall?.contactDestination) errors.contactDestination = t('template.errors.emptyField')
      if (!template.dunningPhoneCall?.callCanevas) errors.callCanevas = t('template.errors.emptyField')
      break

    case 'DunningLegalAction':
      if (!template.dunningLegalAction?.content) errors.content = t('template.errors.emptyField')
      break
  }

  // if (kind === 'DunningEmail') {
  //   let mails
    
  //   if (!template.dunningEmail?.emailFrom) {
  //     errors.emailFrom = t('template.errors.missingMail')
  //   } else {
  //     if (template.dunningEmail?.emailFrom?.length > 1) errors.emailFrom = t('template.errors.onlyOneMail')

  //     mails = checkMails(template.dunningEmail.emailFrom)
  //     if (mails) errors.emailFrom = t('template.errors.badValues', { error: mails })
  //   }
  //   if (!template.dunningEmail?.emailsTo) {
  //     errors.emailsTo = t('template.errors.missingMail')
  //   } else {
  //     mails = checkMails(template.dunningEmail.emailsTo)
  //     if (mails) errors.emailsTo = t('template.errors.badValues', { error: mails })
  //   }

  //   mails = checkMails(template.dunningEmail.emailsCC)
  //   if (mails) errors.emailsCC = t('template.errors.badValues', { error: mails })

  //   mails = checkMails(template.dunningEmail.emailsCCI)
  //   if (mails) errors.emailsCCI = t('template.errors.badValues', { error: mails })

  //   if (!template.dunningEmail.subject) errors.subject = t('template.errors.emptyField')
  //   if (!template.dunningEmail.body) errors.body = t('template.errors.emptyField')
  // } else if (kind === 'DunningPostalLetter') {
  //   if (!template.dunningPostalLetter?.contactDestination) errors.contactDestination = t('template.errors.emptyField')
  //   if (!template.dunningPostalLetter?.letterSubject) errors.letterSubject = t('template.errors.emptyField')
  //   if (!template.dunningPostalLetter?.letterBody) errors.letterBody = t('template.errors.emptyField')
  // } else if (kind === 'DunningPhoneCall') {
  //   if (!template.dunningPhoneCall?.contactDestination) errors.contactDestination = t('template.errors.emptyField')
  //   if (!template.dunningPhoneCall?.callCanevas) errors.callCanevas = t('template.errors.emptyField')
  // } else if (kind === 'DunningLegalAction') {
  //   if (!template.dunningLegalAction?.content) errors.content = t('template.errors.emptyField')
  // }

  return Object.keys(errors).length > 0 ? errors : null
}

/**
 * GENERATE TEMPLATE ITEM (API PAYLOAD) FROM FORM
 * @param {DunningMessageTemplateItem} prefValue 
 * @param {any} form 
 * @returns 
 */
const genTemplateFromForm = (prefValue: DunningMessageTemplateItem, form: any, language: string) => {
  const excludeKeys = [null, undefined, 'updated', 'updatedByUserId', 'isSystemDefault']
  const contactKeys = ['emailFrom', 'emailsTo', 'emailsCC', 'emailsCCI', 'contactDestination']

  // create key for DunningMessageTemplateItem object
  const kindKey = prefValue.kind[0].toLowerCase() + prefValue.kind.slice(1)
  if (!prefValue[kindKey as keyof DunningMessageTemplateItem]) prefValue = { ...prefValue, [kindKey]: {} }

  // generate template
  const template = Object.entries(prefValue).reduce((acc: any, cur) => {
    const [key, value] = cur

    if (excludeKeys.includes(key) || [null, undefined].includes(<any>value)) return acc

    // convert object with value from DunningMessageKind / media
    if (typeof value === 'object' && (stringNormalize(key) === stringNormalize(kindKey))) {
      // remove empty keys or replace value from form
      form.forEach((f: any) => {
        if (!f.value) {
          delete value[f.key]
        } else {
          value[f.key] = f.value
        }
      })

      // convert value string to object
      acc[key] = Object.entries(value).reduce((a: any, c: any) => {
        const [k, v] = c
        const values = contactKeys.includes(k) ? stringToValue(v, prefValue.kind) : convertEditorToData(v)

        a[k] = values

        return a
      }, {})
    } else {
      acc[key] = value
    }

    return acc
  }, {} as DunningMessageTemplateItem)

  return template
}

/**
 * CHECK IF FORM INPUT HAS ERROR
 * @param {DunningMessageTemplateItem} prefValue 
 * @param {any} form 
 * @param {string} language 
 * @param {string} inputName 
 * @returns 
 */
export const checkDunningTemplateInput = ( prefValue: DunningMessageTemplateItem, form: any, language: string, inputName:string):string => {

  const template = genTemplateFromForm(prefValue, form, language)
  const templateError = checkTemplate(prefValue.kind, template)

  if (!templateError) return ''  
  
  return templateError[inputName] ?? ''
}


/**
 * SAVE DUNNING TEMPLATE AND PREFERENCE FROM FORM
 * @param {DunningMessageTemplatePrefKey} prefKey 
 * @param {DunningMessageTemplateItem} prefValue 
 * @param {any} form 
 * @returns 
 */
export const saveDunningTemplate = async (
  prefKey: DunningMessageTemplatePrefKey,
  prefValue: DunningMessageTemplateItem,
  form: any,
  language: string
): Promise<any[] | string | FieldsErrors | null> => {
  const workspaceId: string = get(WorkspaceStore).workspaceId
  
  try {
    const template = genTemplateFromForm(prefValue, form, language)
    const templateError = checkTemplate(prefValue.kind, template)
    
    if (templateError) return templateError

    if (template.kind === DunningMessageKind.DunningEmail) {
      template.dunningEmail.emailFrom = template.dunningEmail.emailFrom[0]
    }

    // Prohibits overwriting the default template and generates a new id
    if (template.isSystemDefault) {
      template.templateItemRef = uuidv4()
      template.isSystemDefault = false
    }

    const resTemplateItem = await saveDunningMessageTemplateItem(workspaceId, template)

    if (resTemplateItem.failures?.length) {
      remoteDebuggingService.addInfo(resTemplateItem.failures, 'error')

      return resTemplateItem.failures[0]
    } else {
      const preference: PersistedPreference = {
        kind: 'DunningMessageTemplate',
        workspaceId,
        dunningMessageTemplate: {
          prefKey,
          'templateItemRef': template.templateItemRef
        }
      }

      const resPreference = await savePreference(workspaceId, preference)
      if (resPreference.failures?.length) {
        remoteDebuggingService.addInfo(resPreference.failures, 'error')

        return resPreference.failures[0]
      } else {
        return ''
      }
    }

  } catch (err) {
    remoteDebuggingService.addInfo(err, 'error')

    if (err?.message) return err.message
    
    return String(err)
  }
}

/**
 * GET LIST OF DunningMessageTemplateItem FRO SPECIFIC WORKSPACE
 * @param worskpaceId
 * @returns {DunningMessageTemplateItem[] }
 */
export const getDunningMessageTemplateItems = async () => {
  const workspaceId: string = get(WorkspaceStore).workspaceId

  try {
    return await apiGet(`/workspace/${workspaceId}/dunning-message-template-items`, <APICallOptions>{
      entity: APIEntity.TOTUM_PREFERENCE,
      ignoreFeedback: true
    })
  } catch (err) {
    remoteDebuggingService.addInfo(err, 'error')
  }
}


const convertInputToSendGridEmail = (value:string):SendGridEmailRecipient[] => {
  if (!value) return []

  const mails = splitContactString(value)
  const contactsStore = get(ContactsStore)
  
  return mails.reduce((acc:SendGridEmailRecipient[], cur) => {

    if (cur.includes('@')) {
      // contact
      const contact = contactsStore.find(c => cur === c.email)

      if (contact) {
        acc.push({ name: `${contact.firstName} ${contact.lastName}`, 'email': contact.email })
      } else {
        // litteral
        acc.push({ name: '', 'email': cur }) // TODO : extraire prenom/nom du mail ?
      }
    } else {
      // placeholder
      // TODO
    }

    return acc
  }, [])
}

/**
 * 
 * @param {FormField[]} formFields
 */
export const dunningTemplateToMail = async (formFields: FormField[], language:string, customerId:string, invoiceId:string) => {
  if (!formFields) return
  
  const profileStore = get(ProfileStore)
  const workspaceStore = get(WorkspaceStore)
  
  const customer = get(CustomersStore).find(c => c.company.companyId === customerId) ?? {} as Customer
  const invoice = get(DunningInvoicesStore).find(i => i.dataId === invoiceId) ?? {} as Invoice

  const paymentLink = await generatePaymentLinkForInvoiceAndCustomer(invoice, customer)

  const selectedContacts = convertInputToSendGridEmail(formFields.find(f => f.key === 'emailsTo')?.value)
  const selectedCcContacts = convertInputToSendGridEmail(formFields.find(f => f.key === 'emailsCC')?.value)
  const selectedBccContacts = convertInputToSendGridEmail(formFields.find(f => f.key === 'emailsCCI')?.value)

  const fromName = substTiphaine(workspaceStore.company.companyId, 'dunningMessageFromFullName', `${profileStore.firstName} ${profileStore.lastName} ${t('todoAction.emailFrom')} ${workspaceStore.company?.formalName}`)

  const replyToEmail = substTiphaine(workspaceStore.company.companyId, 'dunningMessageReplyToEmail', profileStore.email)

  const replyToFullName = substTiphaine(workspaceStore.company.companyId, 'dunningMessageReplyToFullName', profileStore.firstName + ' ' + profileStore.lastName)

  const renderedHeader = populateEmailHeader(get(WorkspaceStore))
  
  let renderedSubjectMessage = formFields.find(f => f.key === 'subject')?.value
  if (language) renderedSubjectMessage = renderedSubjectMessage[language]
  renderedSubjectMessage = replacePlaceholders(renderedSubjectMessage, invoiceId, { paymentLink })
  
  let renderedBodyMessage = formFields.find(f => f.key === 'body')?.value
  if (language) renderedBodyMessage = renderedBodyMessage[language]
  renderedBodyMessage = replacePlaceholders(renderedBodyMessage, invoiceId, { paymentLink })

  const senderLogoUrl = workspaceStore.company.emailLogoURL
  const senderName = workspaceStore.company.formalName
  const senderSiren = workspaceStore.company.regulatory.frRegulScope?.siren
  const address = workspaceStore.company.mailAddress
  let senderAddress:string = `<tr><td>${address.street}</td></tr>`
  if (address.extraAddressLine) senderAddress += `<tr><td>${address.extraAddressLine}</td></tr>`
  senderAddress += `<tr><td>${address.zipCode} ${address.city}</td></tr>`
  if (address.state) senderAddress += `<tr><td>${address.state}</td></tr>`
  senderAddress += `<tr><td>${address.country}</td></tr>`

  renderedBodyMessage = getEmailWrapper(language, renderedSubjectMessage, renderedBodyMessage, senderName, senderSiren, senderAddress, senderLogoUrl)
  
  return {
    selectedContacts,
    selectedCcContacts,
    selectedBccContacts,
    fromName,
    replyToEmail,
    replyToFullName,
    renderedHeader,
    renderedSubjectMessage,
    renderedBodyMessage,
    customer
  }
}