export default {
  common: {
    locales: 'en-EN', // used by js : Intl.NumberFormat
    languageCodeUC: 'EN',
    languageLabel: 'English',
    timezone: {
      errorAssessing: 'There was an error when setting your time zone'
    },
    supportedLanguages: {
      en_US: 'English (US)',
      fr_FR: 'French (France)'
    },
    agGrid: {
      // Set Filter
      selectAll: '(Select All)',
      selectAllSearchResults: '(Select All Search Results)',
      searchOoo: 'Search...',
      blanks: '(Blanks)',
      noMatches: 'No matches',

      // Number Filter & Text Filter
      filterOoo: 'Filter...',
      equals: 'Equals',
      notEqual: 'Not equal',
      blank: 'Blank',
      notBlank: 'Not blank',
      empty: 'Choose One',

      // Number Filter
      lessThan: 'Less than',
      greaterThan: 'Greater than',
      lessThanOrEqual: 'Less than or equal',
      greaterThanOrEqual: 'Greater than or equal',
      inRange: 'In range',
      inRangeStart: 'from',
      inRangeEnd: 'to',

      // Text Filter
      contains: 'Contains',
      notContains: 'Not contains',
      startsWith: 'Starts with',
      endsWith: 'Ends with',

      // Date Filter
      dateFormatOoo: 'yyyy-mm-dd',

      // Filter Conditions
      andCondition: 'AND',
      orCondition: 'OR',

      // Filter Buttons
      applyFilter: 'Apply',
      resetFilter: 'Reset',
      clearFilter: 'Clear',
      cancelFilter: 'Cancel',

      // Filter Titles
      textFilter: 'Text Filter',
      numberFilter: 'Number Filter',
      dateFilter: 'Date Filter',
      setFilter: 'Set Filter',

      // Side Bar
      columns: 'Columns',
      filters: 'Filters',

      // columns tool panel
      pivotMode: 'Pivot Mode',
      groups: 'Row Groups',
      rowGroupColumnsEmptyMessage: 'Drag here to set row groups',
      values: 'Values',
      valueColumnsEmptyMessage: 'Drag here to aggregate',
      pivots: 'Column Labels',
      pivotColumnsEmptyMessage: 'Drag here to set column labels',

      // Header of the Default Group Column
      group: 'Group',

      // Row Drag
      rowDragRow: 'row',
      rowDragRows: 'rows',

      // Other
      loadingOoo: 'Loading...',
      loadingError: 'ERR',
      noRowsToShow: 'No Rows To Show',
      enabled: 'Enabled',

      // Menu
      pinColumn: 'Pin Column',
      pinLeft: 'Pin Left',
      pinRight: 'Pin Right',
      noPin: 'No Pin',
      valueAggregation: 'Value Aggregation',
      noAggregation: 'None',
      autosizeThiscolumn: 'Autosize This Column',
      autosizeAllColumns: 'Autosize All Columns',
      groupBy: 'Group by',
      ungroupBy: 'Un-Group by',
      addToValues: 'Add ${variable} to values',
      removeFromValues: 'Remove ${variable} from values',
      addToLabels: 'Add ${variable} to labels',
      removeFromLabels: 'Remove ${variable} from labels',
      resetColumns: 'Reset Columns',
      expandAll: 'Expand All',
      collapseAll: 'Close All',
      copy: 'Copy',
      ctrlC: 'Ctrl+C',
      copyWithHeaders: 'Copy With Headers',
      copyWithGroupHeaders: 'Copy with Group Headers',
      paste: 'Paste',
      ctrlV: 'Ctrl+V',
      export: 'Export',
      csvExport: 'CSV Export',
      excelExport: 'Excel Export',

      // Enterprise Menu Aggregation and Status Bar
      sum: 'Sum',
      first: 'First',
      last: 'Last',
      min: 'Min',
      max: 'Max',
      none: 'None',
      count: 'Count',
      avg: 'Average',
      filteredRows: 'Filtered',
      selectedRows: 'Selected',
      totalRows: 'Total Rows',
      totalAndFilteredRows: 'Rows',
      more: 'More',
      to: 'to',
      of: 'of',
      page: 'Page',
      nextPage: 'Next Page',
      lastPage: 'Last Page',
      firstPage: 'First Page',
      previousPage: 'Previous Page',

      // Pivoting
      pivotColumnGroupTotals: 'Total',

      // Enterprise Menu (Charts)
      pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
      pivotChart: 'Pivot Chart',
      chartRange: 'Chart Range',

      columnChart: 'Column',
      groupedColumn: 'Grouped',
      stackedColumn: 'Stacked',
      normalizedColumn: '100% Stacked',

      barChart: 'Bar',
      groupedBar: 'Grouped',
      stackedBar: 'Stacked',
      normalizedBar: '100% Stacked',

      pieChart: 'Pie',
      pie: 'Pie',
      doughnut: 'Doughnut',

      line: 'Line',

      xyChart: 'X Y (Scatter)',
      scatter: 'Scatter',
      bubble: 'Bubble',

      areaChart: 'Area',
      area: 'Area',
      stackedArea: 'Stacked',
      normalizedArea: '100% Stacked',

      histogramChart: 'Histogram',
      histogramFrequency: 'Frequency',

      combinationChart: 'Combination',
      columnLineCombo: 'Column & Line',
      AreaColumnCombo: 'Area & Column',

      // Charts
      pivotChartTitle: 'Pivot Chart',
      rangeChartTitle: 'Range Chart',
      settings: 'Settings',
      data: 'Data',
      format: 'Format',
      categories: 'Categories',
      defaultCategory: '(None)',
      series: 'Series',
      xyValues: 'X Y Values',
      paired: 'Paired Mode',
      axis: 'Axis',
      navigator: 'Navigator',
      color: 'Color',
      thickness: 'Thickness',
      xType: 'X Type',
      automatic: 'Automatic',
      category: 'Category',
      number: 'Number',
      time: 'Time',
      autoRotate: 'Auto Rotate',
      xRotation: 'X Rotation',
      yRotation: 'Y Rotation',
      ticks: 'Ticks',
      width: 'Width',
      height: 'Height',
      length: 'Length',
      padding: 'Padding',
      spacing: 'Spacing',
      chart: 'Chart',
      title: 'Title',
      titlePlaceholder: 'Chart title - double click to edit',
      background: 'Background',
      font: 'Font',
      top: 'Top',
      right: 'Right',
      bottom: 'Bottom',
      left: 'Left',
      labels: 'Labels',
      size: 'Size',
      minSize: 'Minimum Size',
      maxSize: 'Maximum Size',
      legend: 'Legend',
      position: 'Position',
      markerSize: 'Marker Size',
      markerStroke: 'Marker Stroke',
      markerPadding: 'Marker Padding',
      itemSpacing: 'Item Spacing',
      itemPaddingX: 'Item Padding X',
      itemPaddingY: 'Item Padding Y',
      layoutHorizontalSpacing: 'Horizontal Spacing',
      layoutVerticalSpacing: 'Vertical Spacing',
      strokeWidth: 'Stroke Width',
      lineDash: 'Line Dash',
      offset: 'Offset',
      offsets: 'Offsets',
      tooltips: 'Tooltips',
      callout: 'Callout',
      markers: 'Markers',
      shadow: 'Shadow',
      blur: 'Blur',
      xOffset: 'X Offset',
      yOffset: 'Y Offset',
      lineWidth: 'Line Width',
      normal: 'Normal',
      bold: 'Bold',
      italic: 'Italic',
      boldItalic: 'Bold Italic',
      predefined: 'Predefined',
      fillOpacity: 'Fill Opacity',
      strokeOpacity: 'Line Opacity',
      histogramBinCount: 'Bin count',
      columnGroup: 'Column',
      barGroup: 'Bar',
      pieGroup: 'Pie',
      lineGroup: 'Line',
      scatterGroup: 'X Y (Scatter)',
      areaGroup: 'Area',
      histogramGroup: 'Histogram',
      combinationGroup: 'Combination',
      groupedColumnTooltip: 'Grouped',
      stackedColumnTooltip: 'Stacked',
      normalizedColumnTooltip: '100% Stacked',
      groupedBarTooltip: 'Grouped',
      stackedBarTooltip: 'Stacked',
      normalizedBarTooltip: '100% Stacked',
      pieTooltip: 'Pie',
      doughnutTooltip: 'Doughnut',
      lineTooltip: 'Line',
      groupedAreaTooltip: 'Area',
      stackedAreaTooltip: 'Stacked',
      normalizedAreaTooltip: '100% Stacked',
      scatterTooltip: 'Scatter',
      bubbleTooltip: 'Bubble',
      histogramTooltip: 'Histogram',
      columnLineComboTooltip: 'Column & Line',
      areaColumnComboTooltip: 'Area & Column',
      customComboTooltip: 'Custom Combination',
      noDataToChart: 'No data available to be charted.',
      pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
      chartSettingsToolbarTooltip: 'Menu',
      chartLinkToolbarTooltip: 'Linked to Grid',
      chartUnlinkToolbarTooltip: 'Unlinked from Grid',
      chartDownloadToolbarTooltip: 'Download Chart',
      seriesChartType: 'Series Chart Type',
      seriesType: 'Series Type',
      secondaryAxis: 'Secondary Axis',

      // ARIA
      ariaChecked: 'checked',
      ariaColumn: 'Column',
      ariaColumnGroup: 'Column Group',
      ariaColumnList: 'Column List',
      ariaColumnSelectAll: 'Toggle Select All Columns',
      ariaDateFilterInput: 'Date Filter Input',
      ariaDefaultListName: 'List',
      ariaFilterColumnsInput: 'Filter Columns Input',
      ariaFilterFromValue: 'Filter from value',
      ariaFilterInput: 'Filter Input',
      ariaFilterList: 'Filter List',
      ariaFilterToValue: 'Filter to value',
      ariaFilterValue: 'Filter Value',
      ariaFilteringOperator: 'Filtering Operator',
      ariaHidden: 'hidden',
      ariaIndeterminate: 'indeterminate',
      ariaInputEditor: 'Input Editor',
      ariaMenuColumn: 'Press CTRL ENTER to open column menu.',
      ariaRowDeselect: 'Press SPACE to deselect this row',
      ariaRowSelectAll: 'Press Space to toggle all rows selection',
      ariaRowToggleSelection: 'Press Space to toggle row selection',
      ariaRowSelect: 'Press SPACE to select this row',
      ariaSearch: 'Search',
      ariaSortableColumn: 'Press ENTER to sort',
      ariaToggleVisibility: 'Press SPACE to toggle visibility',
      ariaUnchecked: 'unchecked',
      ariaVisible: 'visible',
      ariaSearchFilterValues: 'Search filter values',

      // ARIA Labels for Drop Zones

      ariaRowGroupDropZonePanelLabel: 'Row Groups',
      ariaValuesDropZonePanelLabel: 'Values',
      ariaPivotDropZonePanelLabel: 'Column Labels',
      ariaDropZoneColumnComponentDescription: 'Press DELETE to remove',
      ariaDropZoneColumnValueItemDescription:
        'Press ENTER to change the aggregation type',
      ariaDropZoneColumnGroupItemDescription: 'Press ENTER to sort',
      // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeperator}{column name}
      ariaDropZoneColumnComponentAggFuncSeperator: ' of ',
      ariaDropZoneColumnComponentSortAscending: 'ascending',
      ariaDropZoneColumnComponentSortDescending: 'descending',

      // ARIA Labels for Dialogs
      ariaLabelColumnMenu: 'Column Menu',
      ariaLabelCellEditor: 'Cell Editor',
      ariaLabelDialog: 'Dialog',
      ariaLabelSelectField: 'Select Field',
      ariaLabelTooltip: 'Tooltip',
      ariaLabelContextMenu: 'Context Menu',
      ariaLabelSubMenu: 'SubMenu',
      ariaLabelAggregationFunction: 'Aggregation Function',

      // Number Format (Status Bar, Pagination Panel)
      thousandSeparator: ',',
      decimalSeparator: '.'
    },
    timezonesList: {
      europeParis: 'Europe/Paris',
      europeBerlin: 'Europe/Berlin',
      europeLondon: 'Europe/London',
      europeBudapest: 'Europe/Budapest',
      europeWarsaw: 'Europe/Warsaw',
      utc: 'UTC',
      etcGMT12: 'Etc/GMT+12',
      etcGMT11: 'Etc/GMT+11',
      pacificSamoa: 'Pacific/Samoa',
      pacificHonolulu: 'Pacific/Honolulu',
      pacificMarquesas: 'Pacific/Marquesas',
      usAlaska: 'US/Alaska',
      americaTijuana: 'America/Tijuana',
      americaLosAngeles: 'America/Los_Angeles',
      americaChihuahua: 'America/Chihuahua',
      americaPhoenix: 'America/Phoenix',
      americaDenver: 'America/Denver',
      americaChicago: 'America/Chicago',
      americaGuatemala: 'America/Guatemala',
      americaMexicoCity: 'America/Mexico_City',
      americaRegina: 'America/Regina',
      americaBogota: 'America/Bogota',
      americaNew_York: 'America/New_York',
      americaIndianapolis: 'America/Indianapolis',
      americaCaracas: 'America/Caracas',
      americaHalifax: 'America/Halifax',
      americaAsuncion: 'America/Asuncion',
      americaCuiaba: 'America/Cuiaba',
      americaSantiago: 'America/Santiago',
      americaLa_Paz: 'America/La_Paz',
      americaSt_Johns: 'America/St_Johns',
      americaBuenos_Aires: 'America/Buenos_Aires',
      americaSao_Paulo: 'America/Sao_Paulo',
      americaCayenne: 'America/Cayenne',
      americaMontevideo: 'America/Montevideo',
      americaGodthab: 'America/Godthab',
      etcGMT2: 'Etc/GMT+2',
      atlanticAzores: 'Atlantic/Azores',
      atlanticCapeVerde: 'Atlantic/Cape_Verde',
      africaCasablanca: 'Africa/Casablanca',
      atlanticReykjavik: 'Atlantic/Reykjavik',
      africaLagos: 'Africa/Lagos',
      africaJohannesburg: 'Africa/Johannesburg',
      asiaDamascus: 'Asia/Damascus',
      europeKiev: 'Europe/Kiev',
      africaWindhoek: 'Africa/Windhoek',
      europeMinsk: 'Europe/Minsk',
      europeIstanbul: 'Europe/Istanbul',
      asiaAmman: 'Asia/Amman',
      asiaBeirut: 'Asia/Beirut',
      asiaJerusalem: 'Asia/Jerusalem',
      asiaCairo: 'Asia/Cairo',
      africaCairo: 'Africa/Cairo',
      asiaRiyadh: 'Asia/Riyadh',
      europeMoscow: 'Europe/Moscow',
      asiaBaghdad: 'Asia/Baghdad',
      africaNairobi: 'Africa/Nairobi',
      asiaTehran: 'Asia/Tehran',
      indianMauritius: 'Indian/Mauritius',
      asiaTbilisi: 'Asia/Tbilisi',
      asiaBaku: 'Asia/Baku',
      asiaYerevan: 'Asia/Yerevan',
      asiaDubai: 'Asia/Dubai',
      asiaKabul: 'Asia/Kabul',
      asiaYekaterinburg: 'Asia/Yekaterinburg',
      asiaKarachi: 'Asia/Karachi',
      asiaTashkent: 'Asia/Tashkent',
      asiaCalcutta: 'Asia/Calcutta',
      asiaColombo: 'Asia/Colombo',
      asiaKathmandu: 'Asia/Kathmandu',
      asiaDhaka: 'Asia/Dhaka',
      asiaAlmaty: 'Asia/Almaty',
      asiaNovosibirsk: 'Asia/Novosibirsk',
      asiaRangoon: 'Asia/Rangoon',
      asiaKrasnoyarsk: 'Asia/Krasnoyarsk',
      asiaBangkok: 'Asia/Bangkok',
      asiaUlaanbaatar: 'Asia/Ulaanbaatar',
      australiaPerth: 'Australia/Perth',
      asiaTaipei: 'Asia/Taipei',
      asiaSingapore: 'Asie/Singapour',
      asiaShanghai: 'Asie/Shanghai',
      asiaIrkutsk: 'Asia/Irkutsk',
      asiaPyongyang: 'Asia/Pyongyang',
      australiaEucla: 'Australia/Eucla',
      asiaSeoul: 'Asia/Seoul',
      asiaTokyo: 'Asia/Tokyo',
      asiaYakutsk: 'Asia/Yakutsk',
      australiaDarwin: 'Australia/Darwin',
      australiaAdelaide: 'Australia/Adelaide',
      australiaHobart: 'Australia/Hobart',
      asiaVladivostok: 'Asia/Vladivostok',
      pacificPort_Moresby: 'Pacific/Port_Moresby',
      australiaBrisbane: 'Australia/Brisbane',
      australiaSydney: 'Australia/Sydney',
      australiaLHI: 'Australia/LHI',
      asiaMagadan: 'Asia/Magadan',
      pacificFiji: 'Pacific/Fiji',
      asiaKamchatka: 'Asia/Kamchatka',
      pacificAuckland: 'Pacific/Auckland',
      etcGMTMinus12: 'Etc/GMT-12',
      pacificChatham: 'Pacific/Chatham',
      pacificEnderbury: 'Pacific/Enderbury',
      pacificKiritimati: 'Pacific/Kiritimati',
      indianReunion: 'Indian/Reunion',
      indianMayotte: 'Indian/Mayotte',
      americaGuadeloupe: 'America/Guadeloupe',
      americaMartinique: 'America/Martinique',
      pacificGambier: 'Pacific/Gambier',
      pacificMidway: 'Pacific/Midway',
      pacificWallis: 'Pacific/Wallis',
      pacificTahiti: 'Pacific/Tahiti',
      pacificNoumea: 'Pacific/Noumea',
      antarticaTroll: 'Antarctica/Troll'
    },
    loading: 'Loading...',
    invoice: 'Invoice',
    workspace: 'Workspace',
    bankAccount: 'Bank Account',
    calendar: {
      days: {
        monday: 'Mo',
        tuesday: 'Tu',
        wednesday: 'We',
        thursday: 'Th',
        friday: 'Fr',
        saturday: 'Sa',
        sunday: 'Su'
      },
      months: {
        january: 'Janvier',
        february: 'F&eacute;vrier',
        march: 'Mars',
        april: 'Avril',
        may: 'Mai',
        june: 'Juin',
        july: 'Juillet',
        august: 'Ao&ucirc;t',
        september: 'Septembre',
        october: 'Octobre',
        november: 'Novembre',
        december: 'D&eacute;cembre'
      }
    },
    timeAgo: {
      now: 'Just now',
      secondsAgo: '%s seconds ago',
      minuteAgo: '1 minute ago',
      minutesAgo: '%s minutes ago',
      hourAgo: '1 hour ago',
      hoursAgo: '%s hours ago',
      dayAgo: 'yesterday',
      daysAgo: '%s days ago',
      weekAgo: '1 week ago',
      weeksAgo: '%s weeks ago',
      monthAgo: '1 month ago',
      monthsAgo: '%s months ago',
      yearAgo: '1 year ago',
      yearsAgo: '%s years ago'
    },
    timeTo: {
      now: 'Right now',
      secondsTo: 'in %s seconds',
      minuteTo: 'in 1 minute',
      minutesTo: 'in %s minutes',
      hourTo: 'in 1 hour',
      hoursTo: 'in %s hours',
      dayTo: 'in 1 day',
      daysTo: 'in %s days',
      weekTo: 'in 1 week',
      weeksTo: 'in %s weeks',
      monthTo: 'in 1 month',
      monthsTo: 'in %s months',
      yearTo: 'in 1 year',
      yearsTo: 'in %s years'
    },
    entities: {
      invoice: 'invoice',
      invoices: 'invoices',
      todo: 'todo',
      todos: 'todos',
      profile: 'profile',
      workspace: 'workspace',
      customer: 'customer',
      customers: 'customers',
      contact: 'contact',
      contacts: 'contacts',
      bankAccount: 'bank account',
      bankConnection: 'bank connection',
      bbb_bank_connection: 'bank connection',
      paymentLink: 'payment link',
      email: 'email',
      history: 'history'
    },
    feedback: {
      general: 'An error occurred.',
      apiError: 'An error occurred while trying to {{action}} {{entity}} <p style="margin-top: 0.2em; font-style: italic;">{{errorMessage}}</p>',
      action: {
        create: 'create',
        read: 'read',
        update: 'update',
        delete: 'delete'
      },
      verifyEmail: 'Please follow the  instructions sent to your email address.',
      bbbAmountExceeded: 'Cannot create payment link.Transfers above 35000€ are not authorized.',
      emailAddressNotProvided: 'Please provide a valid e-mail adress.'
    },
    email: {
      welcome: {
        subject: 'A big warm welcome from all of us here at Dundy'
      },
      invitation: {
        subject: 'You were invited to Dundy',
        success: 'Team Member Invited'
      }
    },
    amountsMeter: {
      outstanding: 'due',
      overdue: 'Late',
      onTime: 'On Time',
      onTimeTip: '<b>On Time</b>: you have issued an invoice but it <b>is not yet due</b> because its due date hasn\'t happened yet.',
      overdueTip: '<b>Late</b>: invoice whose due date - <b>the payment deadline</b> - has passed. Payment of this invoice is due.'
    },
    missingContact: {
      text: 'Missing Contact',
      link: 'Add Contact'
    },
    missingBankAccount: {
      text: 'Missing Bank Account',
      link: 'Add Bank Account'
    },
    form: {
      labels: {
        show: 'Show',
        hide: 'Hide'
      },
      validation: {
        invalidEmailDomain: '{{domain}} addresses are not accepted.',
        invalidSIREN: 'Please insert a number with 9 or 14 digits.',
        invalidVAT: 'Please insert a valid VAT number.',
        invalidPhoneNumber: 'Please insert a valid phone number.'
      }
    },
    errorPage: {
      pageNotFound: {
        title: 'Page Not Found',
        backHome: 'Go to my dashboard'
      }
    },
    topMenu: {
      nav: {
        dashboard: 'Today',
        transactions: 'Transactions',
        payments: 'Payments',
        getPaidSection: 'Get Paid',
        quotes: 'Quotes',
        invoices: 'Invoices',
        dunning: 'Dunning',
        clients: {
          title: 'Clients',
          manage: 'Manage clients',
          agingBalance: 'Aging balance'
        },
        workspaces: 'Workspaces',
        profileSection: 'Profile & Settings',
        settings: {
          templates: 'Templates',
          reminderWorkflow: 'Reminder workflow',
          profileSettings: 'My Profile',
          invoicingSettings: 'Invoicing Settings',
          companySettings: 'Company Settings',
          bankAccounts: 'Bank Accounts',
          exports: 'Exports'
        },
        team: {
          label: 'Team',
          inviteButton: 'Invite'
        },
        logout: 'Sign out',
        exports: 'Exports'
      },
      userNav: {
        forLabel: 'for',
        signedAs: 'Signed in as',
        teamMember_one: '{{count}} team member',
        teamMember_other: '{{count}} team members',
        manageWorkspaces: 'Manage Workspaces',
        profile: 'Profile Settings',
        invoicing_app: 'Invoicing Settings',
        company: 'Company Settings',
        bank: 'Bank Settings',
        logOut: 'Sign out'
      }
    },
    dashboard: {
      todoModule: {
        loading: 'Loading your tasks...',
        noMoreTasks: {
          title: 'You have no more tasks to do',
          subtitle: 'Add new invoices and track them to generate new tasks.'
        }

      },
      debtorsModule: {
        title: 'Main debtors',
        seeAll: 'All my debtors',
        noMoreDebtors: {
          title: 'You have no more debtors',
          subtitle: 'Bravo ! Réduire le nombre de débiteurs est un excellent moyen d\'améliorer votre trésorerie.'
        },
        NoDebtorsYet: {
          copy: 'Vous n’avez aucun débiteur pour le moment.'
        }
      }
    },
    signIn: {
      back: 'Return',
      createAccount: 'Create account',
      title: 'Nice to see you again!',
      subtitle: 'Let’s collect those dues.',
      email: 'Email',
      errorEmail: 'Please enter a valid email',
      password: 'Password',
      errorPassword: 'Please enter a valid password',
      forgotPassword: 'Forgot your password ?',
      login: 'Login to your workspace',
      notRegistered: 'Not registered yet?',
      loginFailed: 'Something went wrong.'
    },
    signUp: {
      back: 'Back to homepage',
      signIn: 'Sign in',

      loginFailed: 'Login failed.'
    },
    resetPassword: {
      resetFailed: 'Password reset failed.',
      resetSuccessful: 'Password reset successful.',
      checkMail: 'Check your mail inbox',
      back: 'Back to homepage ',
      signIn: 'Sign in',
      title: 'Having trouble signing in ?',
      subtitle: 'Enter your email to reset your password.',
      email: 'Email',
      errorEmail: 'Please enter a valid email',
      reset: 'Reset my password',
      note: 'You’ve come to the right place to reset a forgotten password. For your security,',
      boldnote:
        'we’ll send you an email containing a link to restore access to your account.'
    },
    pagination: {
      label1: 'Showing',
      label2: 'to',
      label3: 'of',
      label4: 'items'
    },
    workspaces: {
      intro: 'Select the workspace you wish to enable.',
      add: 'Add new workspace',
      delete: 'Delete workspace',
      search: {
        placeholder: 'Search by company name or bank'
      },
      empty: {
        title: 'No workspaces yet',
        first_paragraph: 'Start with creating a new workspace.'
      },
      dataTable: {
        heading: 'WEEKLY TODOS',
        columnOneHeading: 'Priority',
        columnTwoHeading: 'Todo Date',
        columnThreeHeading: 'Invoice #',
        columnFourHeading: 'Invoice Amount',
        columnFiveHeading: 'Status',
        columnSixHeading: 'Days Due',
        footerText: 'Weekly\'s Due Amount:',
        dataSheet: 'Sheet '
      },
      export: 'Export Workspaces Data',
      loadingData: 'Loading Export Data...',
      selectSuccess: 'Workspace changed successfully',
      modalHeading: 'Workspace Details'
    },
    editWorkspace: {
      error: 'Update failed',
      title: 'Workspace Details',
      cancel: 'Cancel',
      save: 'Save changes',
      loading: 'Working...',
      customerAdded: 'Workspace Added',
      customerUpdated: 'Workspace Updated',
      addOrEditWorkspaceDetails: 'Fill in the details of the workspace you are creating.',
      saveWorkspace: 'Save Workspace'
    },
    firstTask: {
      title: 'Let’s get started!',
      subtitle: {
        one: 'You are ',
        two: '1',
        three:
          '  task away from getting your coach working for you! This should take you about ',
        four: '5 minutes',
        five: ' and get you ',
        six: 'onboarded'
      },
      card: {
        title: {
          one: 'Add',
          two: ' your first invoice by uploading it or manually filling in your information'
        },
        subtitle:
          'Dundy helps you by keeping track of your invoices, freeing you to take care of your business'
      }
    },
    addInvoiceTask: {
      card: {
        title: {
          one: 'Add',
          two: ' new invoices by uploading them or manually filling in their information'
        },
        subtitle:
          'Dundy helps you with your cash application: you only need to approve the transactions!'
      }
    },
    transactions: {
      hud: {
        expenses: {
          title: 'Expenses',
          tooltip: 'All expenses made on your bank account during the selected period.'
        },
        revenue: {
          title: 'Revenue',
          tooltip: 'All receipts made to your bank account during the selected period.'
        },
        cashburn: {
          title: 'Cashburn',
          tooltip: 'Cashburn is the difference between receipts and expenses. It represents the amount of cash you used during the selected period.'
        }

      },
      pageTitle: 'Transactions',
      empty: {
        title: 'This account contains no transactions',
        subtitle: 'Change your account or come back later.',
        button: 'Change bank account'
      },
      refreshTransactions: {
        refreshTransactionsButtonTitle: 'Refresh transactions',
        refreshTransactionsNoTransaction: 'No received payment'
      },
      error: {
        title: 'Your transactions could not be loaded',
        subtitle: 'Please connect your bank account or verify your bank account connection to continue.',
        button: 'Connect my bank account'
      },
      header: {
        date: 'Date of operation',
        operation: 'Transaction',
        amount: 'Amount',
        account: 'Account'

      }
    },
    supportedCurrenciesList: {
      euroLabel: 'Euro (€)',
      dollarLabel: 'American Dollar ($)',
      swissFrancLabel: 'Swiss Franc (CHF)',
      poundLabel: 'British Pound (£)',
      yenLabel: 'Japanese Yen (¥)',
      canadianDollarLabel: 'Canadian Dollar (CAD)',
      australianDollarLabel: 'Australian Dollar (AUD)',
      newZealandDollarLabel: 'New Zealand Dollar (NZD)'
    },
    unitTypesList: {
      unit_unit: 'Unité',
      unit_unit_one: 'Unité',
      unit_unit_other: 'Unités',
      unit_gram_one: 'Gramme',
      unit_gram_other: 'Grammes',
      unit_hour_one: 'Heure',
      unit_hour_other: 'Heures',
      unit_day_one: 'Jour',
      unit_day_other: 'Jours',
      unit_week_one: 'Semaine',
      unit_week_other: 'Semaines',
      unit_month_one: 'Mois',
      unit_month_other: 'Mois',
      unit_kg_one: 'Kilogramme',
      unit_kg_other: 'Kilogrammes',
      unit_km_one: 'Kilomètre',
      unit_km_other: 'Kilomètres',
      unit_liter_one: 'Litre',
      unit_liter_other: 'Litres',
      unit_lot_one: 'Lot',
      unit_lot_other: 'Lots',
      unit_meter_one: 'Mètre',
      unit_meter_other: 'Mètres',
      unit_square_meter_one: 'Mètre carré',
      unit_square_meter_other: 'Mètres carrés',
      unit_cubic_meter_one: 'Mètre cube',
      unit_cubic_meter_other: 'Mètres cubes',
      unit_linear_meter_one: 'Mètre linéaire',
      unit_linear_meter_other: 'Mètres linéaires',
      unit_person_one: 'Personne',
      unit_person_other: 'Personnes',
      unit_ton_one: 'Tonne',
      unit_ton_other: 'Tonnes'
    },
    quotes: {
      pageTitle: 'Mes devis',
      tabs: {
        all: 'Tous les devis',
        accepted: 'Acceptés',
        draft: 'Brouillons',
        toSend: 'À envoyer'
      },
      columns: {
        client: 'Client',
        customerName: 'Nom du client',
        quoteNumber: 'Devis',
        issuedDate: 'Émission',
        amount: 'Montant TTC',
        amountIncludingTaxes: 'Montant(taxes inclues)',
        amountExcludingTaxes: 'Montant (hors taxes)',
        currency: 'Devise',
        vat: 'TVA',
        taxesPercent: 'Taxes %',
        taxesAmount: 'Total Taxes',
        dueDate: 'Échéance',
        status: 'Statut'
      },
      menu: {
        delete: 'Supprimer',
        customer: 'Afficher le client',
        edit: 'Modifier le devis',
        view: 'Voir le devis'
      },
      createQuote: {
        newQuoteHeader: 'Nouveau devis',
        draftQuoteHeader: 'Brouillon de devis',
        quoteDetailsTitle: 'Détails du devis',
        automaticNumberingLabel: 'Numéro automatique du devis',
        paymentTitle: 'Paiement',
        paymentTermsLabel: 'Modalités de paiement'
      },
      viewQuote: {
        menu: {
          itemDetails: 'Détails',
          itemActivity: 'Activité',
          itemSettings: 'Paramètres'
        }
      },
      form: {
        quoteContent: {
          label: 'Contenu du devis',
          titleLabel: 'Titre de document',
          descriptionLabel: 'Description'
        },
        dateIssued: {
          label: 'Date d\'émission',
          error: 'Pas une date valide'
        },
        dateExpired: {
          label: 'Date d\'expiration',
          error: 'Cette date n\'est pas valide'
        }
      }
    },
    uploadAttachments: {
      dragAndDrop: 'Drag & drop your',
      attachment: 'file',
      or: 'or',
      browseFiles: 'browse files',
      onYourComputer: 'on your computer',
      uploadingAttachment: 'Uploading the file...',
      newUpload: 'New Upload',
      drop: 'Drop file here or click to select.',
      uploadButton: 'Upload File',
      close: 'Close',
      errors: {
        maxSize: 'File size cannot exceed 10MB.',
        accept: 'File type not supported'
      }
    },
    invoices: {
      pageTitle: 'Invoices',
      states: {
        voided: 'Voided',
        creditNote: 'Credit Note',
        draft: 'Draft'
      },
      sources: {
        voxy: 'Invoice Editor',
        dundy: 'Extract'
      },
      total: 'Total',
      tabs: {
        all: 'All invoices',
        tracking: 'Tracking',
        paid: 'Paid',
        creditNotesAndVoidedInvoices: 'Credit Notes',
        voided: 'Voided',
        draft: 'Drafts',
        toSend: 'To send'
      },
      filters: {
        all: 'Show all',
        paid: 'Show paid',
        outstanding: 'Show outstanding',
        deleted: 'Show deleted'
      },
      controls: {
        exportFECFile: 'Export FEC file',
        exportZIPFile: 'Export ZIP file',
        deleteSelectedInvoice_one: 'Delete (1)',
        deleteSelectedInvoice_other: 'Delete ({{count}})'
      },
      columns: {
        client: 'Client',
        customerName: 'Client name',
        invoiceNumber: 'Invoice No.',
        issuedDate: 'Issued',
        amount: 'Amount total',
        totalIncludingTaxes: 'Total amount',
        installmentIncludingTaxes: 'Installment incl. taxes',
        amountIncludingTaxes: 'Amount including taxes',
        amountExcludingTaxes: 'Amount excluding taxes',
        currency: 'Currency',
        vat: 'VAT',
        taxesPercent: 'Taxes %',
        taxesAmount: 'Taxes amount',
        dueDate: 'Due',
        status: 'Status'
      },
      datatable: {
        createSearchedCustomerOne: 'Create " ',
        createSearchedCustomerTwo: ' "',
        createNewCustomer: 'Create new customer',
        chooseACustomer: 'Choose a customer',
        missingCompanyName: '(company name tbd)',
        createNewContact: 'New contact'
      },
      menu: {
        delete: 'Delete Invoice',
        dunning: 'Envoyer un rappel',
        paid: 'Mark as paid',
        unpaid: 'Mark as unpaid',
        untrack: 'Stop tracking',
        track: 'Start tracking',
        customer: 'View Customer',
        edit: 'Edit Invoice',
        view: 'View Invoice',
        action: 'Send reminder'
      },
      actionMenu: {
        createInvoice: 'Create Invoice',
        createNewCreditNote: 'Create Credit Note'

      },
      duplicateInvoice: {
        duplicateInvoiceSuffix: '(copy)',
        duplicateSuccess: 'Your invoice was successfully duplicated!',
        duplicateError: 'An error occurred while duplicating the invoice.'
      },
      uploadInvoice: {
        dragAndDrop: 'Drag & drop your',
        invoice: 'invoice',
        or: 'or',
        browseFiles: 'browse files',
        onYourComputer: 'on your computer',
        uploadingInvoice: 'Uploading the invoice...',
        newUpload: 'New Upload',
        drop: 'Drop invoice here or click to select.',
        uploadButton: 'Upload Invoice',
        close: 'Close',
        errors: {
          maxSize: 'File size cannot exceed 10MB.',
          accept: 'Only PDF files are accepted'
        }
      },
      businessDocument: {
        commonWords: {
          description: 'Object:'
        },
        mainHeaderTitle: {
          invoicePreviewAndPDF: 'Invoice',
          intermediateInvoicePreviewAndPDF: 'Intermediate invoice',
          depositInvoicePreviewAndPDF: 'Deposit invoice',
          balanceInvoicePreviewAndPDF: 'Balance invoice',
          commercialInvoicePreviewAndPDF: 'Commercial Invoice',
          correctiveInvoicePreviewAndPDF: 'Corrective Invoice',
          creditNotePreviewAndPDF: 'Credit Note',
          commercialCreditNotePreviewAndPDF: 'Commercial Credit Note',
          correctiveCreditNotePreviewAndPDF: 'Corrective Credit Note',
          quotePreviewAndPDF: 'Quote',
          purchaseOrderPreviewAndPDF: 'Purchase Order',
          documentPreviewAndPDF: 'Document',
          installmentInvoicePreviewAndPDF: 'Invoice',
          commercialInstallmentInvoicePreviewAndPDF: 'Commercial Invoice',
          correctiveInstallmentInvoicePreviewAndPDF: 'Corrective Invoice',
          installmentCreditNotePreviewAndPDF: 'Credit Note',
          commercialInstallmentCreditNotePreviewAndPDF: 'Commercial Credit Note',
          correctiveInstallmentCreditNotePreviewAndPDF: 'Corrective Credit Note',
          installmentQuotePreviewAndPDF: 'Quote',
          installmentPurchaseOrderPreviewAndPDF: 'Purchase Order',
          installmentDocumentPreviewAndPDF: 'Document'
          // ------ with Partial mentioned ---------------------------
          // installmentInvoicePreviewAndPDF:              "Facture partielle",
          // commercialInstallmentInvoicePreviewAndPDF:    "Facture de vente partielle",
          // correctiveInstallmentInvoicePreviewAndPDF:    "Facture partielle rectificative",
          // installmentCreditNotePreviewAndPDF:           "Avoir partiel",
          // commercialInstallmentCreditNotePreviewAndPDF: "Avoir commercial partiel",
          // correctiveInstallmentCreditNotePreviewAndPDF: "Avoir rectificatif partiel",
          // installmentQuotePreviewAndPDF:                "Devis partiel",
          // installmentPurchaseOrderPreviewAndPDF:        "Bon de commande partiel",
          // installmentDocumentPreviewAndPDF:             "Document partiel",
          // ---------------------------------
        },
        businessDocumentKindWithPercentage: {
          depositInvoice: 'Deposit of {{percentage}}%',
          partialInvoice: 'Intermediary invoice of {{percentage}}%',
          balanceInvoice: 'Balance of {{percentage}}%'
        },
        businessDocumentRelationKind: {
          // quoteInvoicing:             "Facture {{toNumber}} ({{toDocumentKindName}}) issue du devis {{fromNumber}} ({{fromDocumentKindName}})",
          // invoiceDuplication:         "Facture {{toNumber}} ({{toDocumentKindName}}) copie de la facture {{fromNumber}} ({{fromDocumentKindName}})",
          // invoiceVoiding:             "Avoir {{toNumber}} ({{toDocumentKindName}}) annulant la facture {{fromNumber}} ({{fromDocumentKindName}})",
          // creditNoteOnInvoice:        "Avoir {{toNumber}} ({{toDocumentKindName}}) sur la facture {{fromNumber}} ({{fromDocumentKindName}})",
          // invoiceOnCreditNote:        "Facture {{toNumber}} ({{toDocumentKindName}}) sur l'avoir {{fromNumber}} ({{fromDocumentKindName}})",
          // partialQuoteInvoicing:      "Facture partielle {{toNumber}} ({{toDocumentKindName}}) issue du devis {{fromNumber}} ({{fromDocumentKindName}})",
          // partialInvoiceDuplication:  "Facture {{toNumber}} ({{toDocumentKindName}}) copie partielle de la facture {{fromNumber}} ({{fromDocumentKindName}})",
          // partialInvoiceVoiding:      "Avoir {{toNumber}} ({{toDocumentKindName}}) annulant partiellement la facture {{fromNumber}} ({{fromDocumentKindName}})",
          // partialCreditNoteOnInvoice: "Avoir partiel {{toNumber}} ({{toDocumentKindName}}) sur la facture {{fromNumber}} ({{fromDocumentKindName}})",
          // partialInvoiceOnCreditNote: "Facture partielle {{toNumber}} ({{toDocumentKindName}}) sur l'avoir {{fromNumber}} ({{fromDocumentKindName}})",
          // other:                      "Document {{toNumber}} ({{toDocumentKindName}}) issu de {{fromNumber}} ({{fromDocumentKindName}})",
          commercialQuoteInvoicing: 'Facture de vente {{toNumber}} issue du devis {{fromNumber}}',
          correctiveQuoteInvoicing: 'Facture rectificative {{toNumber}} issue du devis {{fromNumber}}',
          quoteInvoicing: 'Facture {{toNumber}} issue du devis {{fromNumber}}',
          invoiceDuplication: 'Facture {{toNumber}} copie de la facture {{fromNumber}}',
          invoiceVoiding: 'Avoir {{toNumber}} annulant la facture {{fromNumber}} (erreur d\'implémentation)',
          commercialCreditNoteOnCommercialInvoice: 'Avoir commercial {{toNumber}} sur la facture de vente {{fromNumber}}',
          commercialCreditNoteOnCorrectiveInvoice: 'Avoir commercial {{toNumber}} sur la facture rectificative {{fromNumber}}',
          commercialCreditNoteOnInvoice: 'Avoir commercial {{toNumber}} sur la facture {{fromNumber}}',
          voidingCreditNoteOnCommercialInvoice: 'Avoir rectificatif {{toNumber}} sur la facture de vente {{fromNumber}}',
          voidingCreditNoteOnCorrectiveInvoice: 'Avoir rectificatif {{toNumber}} sur la facture rectificative {{fromNumber}}',
          voidingCreditNoteOnInvoice: 'Avoir rectificatif {{toNumber}} sur la facture {{fromNumber}}',
          creditNoteOnInvoice: 'AvoirA {{toNumber}} sur la facture {{fromNumber}}',
          commercialInvoiceOnCommercialCreditNote: 'Facture de vente {{toNumber}} sur l\'avoir commercial {{fromNumber}}',
          commercialInvoiceOnCorrectiveCreditNote: 'Facture de vente {{toNumber}} sur l\'avoir rectificatif {{fromNumber}}',
          commercialInvoiceOnCreditNote: 'Facture de vente {{toNumber}} sur l\'avoir {{fromNumber}}',
          correctiveInvoiceOnCommercialCreditNote: 'Facture rectificative {{toNumber}} sur l\'avoir commercial {{fromNumber}}',
          correctiveInvoiceOnCorrectiveCreditNote: 'Facture rectificative {{toNumber}} sur l\'avoir rectificatif {{fromNumber}}',
          correctiveInvoiceOnCreditNote: 'Facture rectificative {{toNumber}} sur l\'avoir {{fromNumber}}',
          invoiceOnCreditNote: 'Facture {{toNumber}} sur l\'avoir {{fromNumber}}',
          partialCommercialQuoteInvoicing: 'Facture de vente {{toNumber}} issue du devis {{fromNumber}}',
          partialCorrectiveQuoteInvoicing: 'Facture rectificative {{toNumber}} issue du devis {{fromNumber}}',
          partialQuoteInvoicing: 'Facture {{toNumber}} issue du devis {{fromNumber}}',
          partialInvoiceDuplication: 'Facture {{toNumber}} copie de la facture {{fromNumber}}',
          partialInvoiceVoiding: 'Avoir {{toNumber}} annulant partiellement la facture {{fromNumber}} (erreur d\'implémentation)',
          partialVoidingCreditNoteOnDepositCommercialInvoice: 'Avoir rectificatif {{toNumber}} sur la facture d\'acompte {{fromNumber}}',
          partialVoidingCreditNoteOnIntermediateCommercialInvoice: 'Avoir rectificatif {{toNumber}} sur la facture intermédiaire {{fromNumber}}',
          partialVoidingCreditNoteOnBalanceCommercialInvoice: 'Avoir rectificatif {{toNumber}} sur la facture de solde {{fromNumber}}',
          partialVoidingCreditNoteOnCommercialInvoice: 'Avoir rectificatif {{toNumber}} sur la facture de vente {{fromNumber}}',
          partialVoidingCreditNoteOnCorrectiveInvoice: 'Avoir rectificatif {{toNumber}} sur la facture corrective {{fromNumber}}',
          partialVoidingCreditNoteOnInvoice: 'Avoir rectificatif {{toNumber}} sur la facture {{fromNumber}}',
          partialCommercialCreditNoteOnCommercialInvoice: 'Avoir commercial {{toNumber}} sur la facture de vente {{fromNumber}}',
          partialCommercialCreditNoteOnCorrectiveInvoice: 'Avoir commercial {{toNumber}} sur la facture rectificative {{fromNumber}}',
          partialCommercialCreditNoteOnInvoice: 'Avoir commercial {{toNumber}} sur la facture {{fromNumber}}',
          partialCreditNoteOnInvoice: 'AvoirB {{toNumber}} sur la facture {{fromNumber}}',
          partialCommercialDepositInvoiceOnCommercialCreditNote: 'Facture d\'acompte {{toNumber}} suivant l\'avoir commercial {{fromNumber}}',
          partialCommercialIntermediateInvoiceOnCommercialCreditNote: 'Facture intermédiaire {{toNumber}} suivant l\'avoir commercial {{fromNumber}}',
          partialCommercialBalanceInvoiceOnCommercialCreditNote: 'Facture de solde {{toNumber}} suivant l\'avoir commercial {{fromNumber}}',
          partialCommercialInvoiceOnCommercialCreditNote: 'Facture de vente {{toNumber}} sur l\'avoir commercial {{fromNumber}}',
          partialCommercialDepositInvoiceOnCorrectiveCreditNote: 'Facture d\'acompte {{toNumber}} suivant l\'avoir rectificatif {{fromNumber}}',
          partialCommercialIntermediateInvoiceOnCorrectiveCreditNote: 'Facture intermédiaire {{toNumber}} suivant l\'avoir rectificatif {{fromNumber}}',
          partialCommercialBalanceInvoiceOnCorrectiveCreditNote: 'Facture de solde {{toNumber}} suivant l\'avoir rectificatif {{fromNumber}}',
          partialCommercialInvoiceOnCorrectiveCreditNote: 'Facture de vente {{toNumber}} sur l\'avoir rectificatif {{fromNumber}}',
          partialCommercialInvoiceOnCreditNote: 'Facture de vente {{toNumber}} sur l\'avoir {{fromNumber}}',
          partialCorrectiveInvoiceOnCommercialCreditNote: 'Facture rectificative {{toNumber}} sur l\'avoir commercial {{fromNumber}}',
          partialCorrectiveInvoiceOnCorrectiveCreditNote: 'Facture rectificative {{toNumber}} sur l\'avoir rectificatif {{fromNumber}}',
          partialCorrectiveInvoiceOnCreditNote: 'Facture rectificative {{toNumber}} sur l\'avoir {{fromNumber}}',
          partialInvoiceOnCreditNote: 'Facture {{toNumber}} sur l\'avoir {{fromNumber}}',
          other: 'Document {{toNumber}} ({{toDocumentKindName}}) issu de {{fromNumber}} ({{fromDocumentKindName}})',
          intermediateInvoiceAfterDepositInvoice: 'Facture intermédiaire {{toNumber}} suivant la facture d\'acompte {{fromNumber}}.',
          intermediateInvoiceAfterIntermediateInvoice: 'Facture intermédiaire {{toNumber}} suivant la facture intermédiaire {{fromNumber}}.',
          balanceInvoiceAfterIntermediateInvoice: 'Facture de solde {{toNumber}} suivant la facture intermédiaire {{fromNumber}}.',
          balanceInvoiceAfterDepositInvoice: 'Facture de solde {{toNumber}} suivant la facture d\'acompte {{fromNumber}}.',
          partialInvoiceOnInvoice: 'Facture {{toNumber}} issue de la facture {{fromNumber}}.'
          // ------ with Partial mentioned ---------------------------
          // commercialQuoteInvoicing:             "Facture de vente {{toNumber}} issue du devis {{fromNumber}}",
          // correctiveQuoteInvoicing:             "Facture rectificative {{toNumber}} issue du devis {{fromNumber}}",
          // quoteInvoicing:             "Facture {{toNumber}} issue du devis {{fromNumber}}",
          // invoiceDuplication:         "Facture {{toNumber}} copie de la facture {{fromNumber}}",
          // invoiceVoiding:             "Avoir {{toNumber}} annulant la facture {{fromNumber}} (erreur d'implémentation)",
          // commercialCreditNoteOnCommercialInvoice:        "Avoir commercial {{toNumber}} sur la facture de vente {{fromNumber}}",
          // commercialCreditNoteOnCorrectiveInvoice:        "Avoir commercial {{toNumber}} sur la facture rectificative {{fromNumber}}",
          // commercialCreditNoteOnInvoice:        "Avoir commercial {{toNumber}} sur la facture {{fromNumber}}",
          // voidingCreditNoteOnCommercialInvoice:        "Avoir rectificatif {{toNumber}} sur la facture de vente {{fromNumber}}",
          // voidingCreditNoteOnCorrectiveInvoice:        "Avoir rectificatif {{toNumber}} sur la facture rectificative {{fromNumber}}",
          // voidingCreditNoteOnInvoice:        "Avoir rectificatif {{toNumber}} sur la facture {{fromNumber}}",
          // creditNoteOnInvoice:        "Avoir {{toNumber}} sur la facture {{fromNumber}}",
          // commercialInvoiceOnCommercialCreditNote:        "Facture de vente {{toNumber}} sur l'avoir commercial {{fromNumber}}",
          // commercialInvoiceOnCorrectiveCreditNote:        "Facture de vente {{toNumber}} sur l'avoir rectificatif {{fromNumber}}",
          // commercialInvoiceOnCreditNote:        "Facture de vente {{toNumber}} sur l'avoir {{fromNumber}}",
          // correctiveInvoiceOnCommercialCreditNote:        "Facture rectificative {{toNumber}} sur l'avoir commercial {{fromNumber}}",
          // correctiveInvoiceOnCorrectiveCreditNote:        "Facture rectificative {{toNumber}} sur l'avoir rectificatif {{fromNumber}}",
          // correctiveInvoiceOnCreditNote:        "Facture rectificative {{toNumber}} sur l'avoir {{fromNumber}}",
          // invoiceOnCreditNote:        "Facture {{toNumber}} sur l'avoir {{fromNumber}}",
          // partialCommercialQuoteInvoicing:      "Facture de vente partielle {{toNumber}} issue du devis {{fromNumber}}",
          // partialCorrectiveQuoteInvoicing:      "Facture rectificative partielle {{toNumber}} issue du devis {{fromNumber}}",
          // partialQuoteInvoicing:      "Facture partielle {{toNumber}} issue du devis {{fromNumber}}",
          // partialInvoiceDuplication:  "Facture {{toNumber}} copie partielle de la facture {{fromNumber}}",
          // partialInvoiceVoiding:      "Avoir {{toNumber}} annulant partiellement la facture {{fromNumber}} (erreur d'implémentation)",
          // partialVoidingCreditNoteOnCommercialInvoice: "Avoir rectificatif partiel {{toNumber}} sur la facture de vente {{fromNumber}}",
          // partialVoidingCreditNoteOnCorrectiveInvoice: "Avoir rectificatif partiel {{toNumber}} sur la facture corrective {{fromNumber}}",
          // partialVoidingCreditNoteOnInvoice: "Avoir rectificatif partiel {{toNumber}} sur la facture {{fromNumber}}",
          // partialCommercialCreditNoteOnCommercialInvoice: "Avoir commercial partiel {{toNumber}} sur la facture de vente {{fromNumber}}",
          // partialCommercialCreditNoteOnCorrectiveInvoice: "Avoir commercial partiel {{toNumber}} sur la facture rectificative {{fromNumber}}",
          // partialCommercialCreditNoteOnInvoice: "Avoir commercial partiel {{toNumber}} sur la facture {{fromNumber}}",
          // partialCreditNoteOnInvoice: "Avoir partiel {{toNumber}} sur la facture {{fromNumber}}",
          // partialCommercialInvoiceOnCommercialCreditNote: "Facture de vente partielle {{toNumber}} sur l'avoir commercial {{fromNumber}}",
          // partialCommercialInvoiceOnCorrectiveCreditNote: "Facture de vente partielle {{toNumber}} sur l'avoir rectificatif {{fromNumber}}",
          // partialCommercialInvoiceOnCreditNote: "Facture de vente partielle {{toNumber}} sur l'avoir {{fromNumber}}",
          // partialCorrectiveInvoiceOnCommercialCreditNote: "Facture rectificative partielle {{toNumber}} sur l'avoir commercial {{fromNumber}}",
          // partialCorrectiveInvoiceOnCorrectiveCreditNote: "Facture rectificative partielle {{toNumber}} sur l'avoir rectificatif {{fromNumber}}",
          // partialCorrectiveInvoiceOnCreditNote: "Facture rectificative partielle {{toNumber}} sur l'avoir {{fromNumber}}",
          // partialInvoiceOnCreditNote: "Facture partielle {{toNumber}} sur l'avoir {{fromNumber}}",
          // other:                      "Document {{toNumber}} ({{toDocumentKindName}}) issu de {{fromNumber}} ({{fromDocumentKindName}})",
          // ---------------------------------
          // quoteInvoicing:                "Facture {{toNumber}} issue du devis {{fromNumber}}",
          // invoiceDuplication:            "Facture {{toNumber}} copie de la facture {{fromNumber}}",
          // invoiceVoiding:                "Avoir {{toNumber}} annulant la facture {{fromNumber}}",
          // creditNoteOnInvoice:           "Avoir {{toNumber}} sur la facture {{fromNumber}}",
          // invoiceOnCreditNote:        "Avoir {{toNumber}} sur l'avoir {{fromNumber}}",
          // partialQuoteInvoicing:         "Facture partielle {{toNumber}} issue du devis {{fromNumber}}",
          // partialInvoiceDuplication:     "Facture {{toNumber}} copie partielle de la facture {{fromNumber}}",
          // partialInvoiceVoiding:         "Avoir {{toNumber}} annulant partiellement la facture {{fromNumber}}",
          // partialCreditNoteOnInvoice:    "Avoir partiel {{toNumber}} sur la facture {{fromNumber}}",
          // partialInvoiceOnCreditNote: "Avoir partiel {{toNumber}} sur l'avoir {{fromNumber}}",
          // other:                         "Document {{toNumber}} issu de {{fromNumber}}",
        },
        kindName: {
          invoiceKindName: 'invoice',
          creditNoteKindName: 'credit note',
          quoteKindName: 'quote',
          purchaseOrderKindName: 'purchase order',
          documentKindName: 'document',
          installmentInvoiceKindName: 'partial invoice',
          installmentCreditNoteKindName: 'partial credit note',
          installmentQuoteKindName: 'partial quote',
          installmentPurchaseOrderKindName: 'partial purchase order',
          installmentDocumentKindName: 'partial document'
        }
      },
      createCreditNote: {
        draftCreditNoteHeader: 'Edit credit note draft',
        creditNoteLabel: 'Credit note',
        creditNoteDetailsTitle: 'Credit note details',
        automaticNumberingLabel: 'Automatic credit note numbering',
        newCreditNoteHeader: 'New credit note',
        creditNoteInformationWithRelatedDocuments: '<p class="text-s">You are currently <strong>creating a credit note</strong> that will cancel <strong>the invoice n° {{invoiceNumber}}</strong> previously addressed to your client <strong>{{clientName}}</strong>.</p>',
        creditNoteInformation: '<p class="text-s">You are currently <strong>creating a commercial credit note</strong>.\n' +
          'A commercial credit note is usefull to <strong>give a discount</strong> to a client\n' +
          '(discount or rebate on a future purchase).'
      },
      resumeInvoicing: {
        resumeTitle: 'Resume invoicing',
        resumeText: 'Click on an invoice to automatically create a balance invoice or a new interim invoice.',
        buttonCancel: 'Cancel & close',
        buttonResume: 'Resume'
      },
      cancelInvoice: {
        warn: 'To cancel the invoice definitively, finalize the credit note now or the draft later.'
      },
      createInvoice: {
        generateIntermediateInvoices: {
          copy: '<b>Partial invoices</b> (deposit or intermediate) were edited for this customer.<br> Resume invoicing.',
          accessButtonLabel: 'Resume',
          resetDissociateBusinessDocument: 'Unlink'
        },
        newInvoiceHeader: 'New invoice',
        draftInvoiceHeader: 'Edit invoice draft',
        noClientYet: 'To create an invoice, start by adding a first customer. You can then select it so that all its information automatically fills your invoice.',
        noContactYet: 'Missing customer contact',
        addLine: 'Add item',
        itemHeader: 'Item #{{count}}',
        editClientFile: 'Edit customer',
        clientLabel: 'Customer:',
        clientInvoiceLabel: 'Customer:',
        noCompleteAddressCustomer: 'Customer address is not complete',
        accountInvoiceLabel: 'Emitted by:',
        editButton: 'Edit',
        addContact: 'Add contact',
        createContactForClient: 'Create a contact for this customer',
        chooseContactPlaceholder: 'Select a contact',
        createClient: 'Add a new customer',
        chooseClientPlaceholder: 'Choose a customer',
        changeClient: 'Change customer',
        invoiceDetailsTitle: 'Invoice details',
        itemsTitle: 'Products / Services',
        itemOptional: '(optional)',
        itemTitle: 'Title',
        itemTitlePlaceholder: 'Your Service or Product',
        itemDescription: 'Description',
        itemDescriptionPlaceholder: 'Fill in your product or service description',
        itemSupplierReference: 'Supplier reference',
        itemSupplierReferencePlaceholder: 'Fill in a product or service reference',
        unitPriceNoTaxes: 'Unit price (excl. taxes)',
        installmentPercentage: 'Pourcentage du total',
        installmentAmountInclTaxes: 'Montant du paiement TTC',
        quantity: 'Quantity',
        vatRate: 'Tax Rate',
        designationLabel: 'Description',
        servicesLabel: 'Services',
        productsLabel: 'Products',
        quantityLabel: 'Qty',
        unitTypeLabel: 'Unit',
        unitPriceNoTaxLabel: 'Unit Price (excl. taxes)',
        taxRateLabel: 'Tax Rate',
        totalPriceNoTaxLabel: 'Total (excl. taxes)',
        finalTotalPriceNoTaxLabel: 'Total (excl. taxes)',
        finalPriceTaxLabel: 'Tax Total',
        finalTotalPriceWithTaxLabel: 'Total (incl. taxes)',
        totalInvoicedPriceNoTaxLabel: 'Net Amount',
        finalInvoicedPriceTaxLabel: 'Tax',
        finalInvoicedTotalPriceWithTaxLabel: 'Full Amount',
        installmentDetailsTitle: 'Invoicing details',
        detailsOfFirstInstallmentInvoice: 'Partial invoice details',
        detailsOfFirstInstallmentCreditNote: 'Partial credit note details',
        detailsOfFirstInstallmentOtherBusinessDocument: 'Partial document details',
        partialInstallmentDescriptionCreditNotePercentageOfAmount: 'Avoir partiel de {{calculatedPercentage}} %',
        partialInstallmentDescriptionInvoicePercentageOfAmount: 'Facture partielle de {{calculatedPercentage}} %',
        partialDepositInstallmentDescriptionInvoicePercentageOfAmount: 'Facture d\'acompte de {{calculatedPercentage}} %',
        partialIntermediateInstallmentDescriptionInvoicePercentageOfAmount: 'Facture intermédiaire de {{calculatedPercentage}} %',
        partialBalanceInstallmentDescriptionInvoicePercentageOfAmount: 'Facture de solde de {{calculatedPercentage}} %',
        partialInstallmentDescriptionDocumentKindPercentageOfAmount: 'Document partiel de {{calculatedPercentage}} %',
        partialInstallmentDescriptionCreditNoteAbsoluteAmount: 'Avoir partiel de {{absoluteValue}}',
        partialInstallmentDescriptionInvoiceAbsoluteAmount: 'Facture partielle de {{absoluteValue}}',
        partialDepositInstallmentDescriptionInvoiceAbsoluteAmount: 'Facture d\'acompte de {{absoluteValue}}',
        partialIntermediateInstallmentDescriptionInvoiceAbsoluteAmount: 'Facture intermédiaire de {{absoluteValue}}',
        partialBalanceInstallmentDescriptionInvoiceAbsoluteAmount: 'Facture de solde de {{absoluteValue}}',
        partialInstallmentDescriptionDocumentKindAbsoluteAmount: 'Document partiel de {{absoluteValue}}',
        partialInstallmentDescriptionDocumentKindChosenKind: 'Document partiel de {{partialValue}}',
        amountDueScaledValueLabel: 'Amount due',
        amountOfInstallmentValueForInvoiceLabel: 'Amount billed',
        amountOfInstallmentValueForCreditNoteLabel: 'Credit note amount',
        amountOfInstallmentValueForBusinessDocumentLabel: 'Partial amount',
        amountToPayScaledValueLabel: 'Balance due',
        discountLabel: 'Discount',
        addDiscount: 'Add discount',
        emptyItemsState: 'Add a product or service to your invoice',
        paymentTitle: 'Payment options',
        payOnlineLabel: 'Pay online',
        paidInFullToggle: 'Paid in full',
        paidInFullToggleCopy: 'Check this box if the customer has already paid the full balance.',
        paymentLink: 'Payment link',
        paymentLinkCopy: 'Once activated, your customers can pay you directly from a dedicated link.',
        activatePaymentNotice: 'To activate the payment link, you must <a href="/settings/bank/view" class="text-cabaret" use:link>connect your bank account to Dundy</a>.',
        sirenNumberWithDataLabel: 'SIREN # : {{siren}}',
        sirenNumberLabel: 'SIREN #',
        vatNumberWithDataLabel: 'VAT # : {{tva}}',
        vatNumberLabel: 'VAT #',
        installmentsTitle: 'Installments',
        installmentsSubTitle: 'Create a deposit or define several payment deadlines. A balance invoice will automatically be created in draft form.',
        installmentsConditions: 'Terms of Payment',
        noInstallmentChoice: 'Full amount',
        yesInstallmentChoice: 'Partial payment',
        totalOfInvoiceShouldBePositive: 'The amount of an invoice must be positive.',
        totalOfCreditNoteShouldBeNegative: 'The amount of a credit must be negative.',
        partialAmountInvoiceTitle: 'Balance due',
        partialAmountCreditNoteTitle: 'Credit note amount',
        partialAmountAnyTitle: 'Applicable amount',
        paymentConditions: 'Commercial terms',
        showPenaltyText: 'View late payment penalties and legal collection fees',
        defaultFrenchPenaltyTerms: 'Late penalties: three times the annual legal interest rate in force calculated from the due date until full payment of the price · Fixed compensation of €40 for recovery costs in accordance with articles L 441-3 and L 441- 6 of the French commercial code ·',
        defaultFrenchNoDiscountEarlyBirds: 'No discount for early payment ·',
        recommendedNotice: 'Recommended',
        showNoDiscountText: 'No discount for early payment',
        addCustomTerms: 'Add my own terms and conditions',
        paymentTermTitle: 'Commercial Terms:',
        customTermsPlaceholder: 'Fill in your terms and conditions...',
        saveAsDraftButton: 'Save draft',
        savingInProgress: 'Saving...',
        saveAndTrackInvoiceButton: 'Finalize and track invoice',
        saveAsCreditNoteButton: 'Finalize credit note',
        finalizingInvoiceInProgress: 'Creating final invoice...',
        finalizingCreditNoteInProgress: 'Creating credit note...',
        dateIssued: 'Issued Date',
        dateDue: 'Due Date',
        dueLabel: 'Due:',
        paymentInformation: 'Bank information:',
        missingBankInformation: 'Some information from your bank account is missing. Please complete them before validating your invoice.',
        missingTaxNumber: 'Missing VAT number',
        missingSIRENNumber: 'Missing SIREN number',
        missingLegalMention: 'Some information from your company is missing. Please complete them before validating your invoice.',
        beneficiaryCompanyName: 'Name of Beneficiary',
        beneficiaryCompanyNameLabel: 'Name of Beneficiary:',
        beneficiaryIBAN: 'IBAN',
        beneficiaryIBANLabel: 'IBAN:',
        beneficiaryBIC: 'BIC',
        beneficiaryBICLabel: 'BIC:',
        invoiceReference: 'Reference',
        invoiceReferenceLabel: 'Reference:',
        successfullySavedDraft: 'Invoice draft saved!',
        successfullySavedFinalInvoice: 'Invoice finalized!',
        successfullySavedFinalCreditNote: 'Credit note finalized!',
        shareCapital: 'with {{socialCapital}} in capital',
        poweredWithDundy: 'Generated with Dundy',
        errorSavingFinal: 'Oops, your invoice seems incomplete. Check that you have completed everything and try again.',
        invoiceNotFinalized: 'We were unable to finalize your invoice. Please refresh the page and try again.',
        errorGeneratingPDF: 'Oops, we can\'t generate your invoice. Try Again.',
        linkedDocuments_one: 'Linked document',
        linkedDocuments_other: 'Linked documents',
        noLinkedDocumentFound: 'No linked document found',
        issuedOn: 'Issued on',
        dueOn: 'Due on',
        automaticNumberingLabel: 'Automatic numbering',
        discountPercentageValueLabel: '%',
        discountEurValueLabel: 'EUR',
        recapThisDocumentPrefix: '(',
        recapThisDocumentSuffix: ')',
        recapThisDocument: 'this document',
        issuedOnDateMentionPrefix: ' on ',
        paidInvoiceLabel: 'Paid invoice',
        notEligibleToVAT: 'VAT not applicable, article 293 B of the CGI',
        buttons: {
          unlinkTooltip: 'Unlink documents',
          viewDocumentTooltip: 'View document'
        },
        settings: {
          currencyTitle: 'Invoice currency',
          currencyCopy: 'Choose the currency in which you want to invoice your customers.'
        }
      },
      addViaOCR: {
        addByOCRTitle: 'Import an invoice using OCR',
        addByOCRCopy: 'Manage your invoices as you go along by sending them to the following e-mail address from any e-mail address. They will then be automatically added and tracked by the system.',
        copyEmailButton: 'Copy e-mail'
      },
      externalInvoice: {
        addInvoiceTitle: 'Add an existing invoice',
        addInvoiceCopy: 'Would you like to import an invoice already created in another program or a paper invoice? Add it here',
        editClientFile: 'Modify customer file',
        changeClient: 'Change',
        invoiceDetailsTitle: 'Invoice details',
        createClient: 'Add a customer',
        uploadInvoice: 'Upload your invoice',
        noAttachment: 'Once you\'ve uploaded your invoice, it will appear here',
        successfullySavedDraft: 'Draft footer saved!',
        successfullySavedFinal: 'Invoice finalized!',
        deleteExternalInvoiceTitle: 'Confirm deletion?',
        deleteExternalInvoiceBody: 'Do you really want to delete this invoice? It will be permanently deleted from our servers. This action cannot be undone.',
        deleteConfirmButtonModal: 'Delete',
        deleteCancelButtonModal: 'Cancel'
      },
      editInvoice: {
        addInvoiceTitle: 'Add new invoice',
        addByOCRTitle: 'Import invoice using OCR',
        clientInformation: 'Let\'s start with your client',
        selectCustomerPlaceholder: 'Choose the customer',
        saving: 'Saving in progress...',
        chooseClient: 'Choose an existing client or create a new one on the fly.',
        createClient: 'Create client',
        issued: 'Issued',
        due: 'Due',
        invoiceDetails: 'Invoice details',
        fillInformation: 'Fill in the information of your invoice.',
        currency: 'Currency',
        uploadInvoice: 'Upload your invoice',
        acceptPDFs: 'We accept PDFs.',
        sizeLimited: 'Size limited to 10Mb.',
        savedInDraft: 'Successfully saved in draft',
        saveAsDraft: 'Save as Draft',
        saveAndTrack: 'Save and start tracking',
        cancel: 'Cancel'
      },
      viewInvoice: {
        buttons: {
          sendInvoiceButton: 'Send invoice',
          dunningButton: 'Send reminder',
          copyButton: 'Duplicate invoice',
          copyDundyButton: 'Duplicate excerpt',
          voidButton: 'Void invoice',
          deleteButton: 'Delete invoice excerpt',
          downloadButtonLabel: 'Download invoice'
        },
        menu: {
          itemDetails: 'Details',
          itemActivity: 'Timeline',
          itemSettings: 'Settings',
          itemRelated_one: 'Linked document',
          itemRelated_other: 'Linked documents'
        },
        total: 'Total incl. taxes',
        issued: 'Issued',
        due: 'Due',
        collectedTax: 'Collected tax',
        leftToCollect: 'Balance left:',
        invoiceDetails: 'Invoice details',
        creditNoteDetails: 'Credit note details',
        invoiceNumberLabel: 'Invoice number:',
        creditNoteLabel: 'Credit note number:',
        invoice: 'Invoice',
        invoiceNumber: 'Invoice #{{count}}',
        timeline: 'Timeline',
        nextAction: 'Next action for this invoice',
        noAttachment: 'Invoice PDF unavailable.',
        invoiceNotFinalized: 'Finalize this invoice before you can view it.',
        voidVoxyInvoiceBody: 'Once the invoice is finalized, a draft credit note of the initial amount will be automatically generated. Would you like to continue ?',
        voidVoxyInvoiceConfirmationTitle: '',
        voidVoxyInvoiceConfirmButtonModal: 'Continue',
        voidVoxyInvoiceCancelButtonModal: 'Cancel'
      },
      form: {
        number: {
          label: 'Invoice Number',
          error: 'Not a valid number'
        },
        amount: {
          label: 'Amount',
          error: 'Not a valid amount'
        },
        currency: {
          label: 'Currency',
          error: 'Not a valid currency'
        },
        dateIssued: {
          label: 'Issued date',
          error: 'Not a valid date'
        },
        dateDue: {
          label: 'Due Date',
          error: 'Not a valid date'
        }
      },
      errorDelete: 'Error deleting invoice(s)',
      errorUpdate: 'Error updating invoices',
      title: 'Invoices',
      subtitleFull: {
        one: 'You have ',
        two_one: ' invoice awaiting payments.',
        two_other: ' invoices awaiting payments.',
        three: ' The total amount to be paid is'
      },
      subtitleEmpty: {
        one: 'There are ',
        two: ' invoices awaiting payments.',
        three: ' Start by adding your invoices to the table.'
      },
      delete: 'Delete invoice',
      restore: 'Restore invoice',
      fetching: 'Fetching results',
      noResults: {
        title: 'NO RESULTS FOUND',
        one: 'There aren’t any invoices matching your search for ',
        two: 'Try again with a different search?'
      },
      autoImport: {
        howItWorks: 'Use the dedicated email address to automatically import your customer invoices into Dundy.',
        shoot: '<a href=\'mailto:{{customMailAddress}}\'>{{customMailAddress}}</a>'
      },
      note: {
        one: 'Too many invoices to fill in?',
        two: ' Send us your files (.csv, jpeg, .doc, .pdf) and we will convert them for you',
        shoot: 'Shoot us an email at work.for.me@dundy.co'
      },
      finalizeConfirmation: {
        text: 'After finalising the invoice cannot be changed. Continue?',
        yes: 'Yes',
        no: 'No'
      },
      finalised: 'Invoice is now being tracked.',
      deleted: 'Invoice(s) deleted successfully.',
      paid_one: 'Invoice marked as paid.',
      paid_other: 'Invoices marked as paid.',
      unpaid_one: 'Invoice marked as unpaid.',
      unpaid_other: 'Invoices marked as unpaid.',
      landscapeNotice: 'While in BETA please use landscape mode on this page.',
      pagination: {
        label1: 'Showing',
        label2: 'to',
        label3: 'of',
        label4: 'invoices'
      }
    },
    dunning: {
      pageTitle: 'Tracking invoices',
      tabs: {
        tracking: 'Tracked',
        late: 'Late',
        paused: 'Paused',
        paid: 'Paid',
        draft: 'Draft'
      },
      visualisationHUD: {
        debtorsTitle: 'Accounts receivable',
        outstandingAmountTitle: 'Customer balance',
        outstandingAmountSubtitle: 'as of {{dateNow}}',
        dueAmountTitle: 'On Time',
        dueAmountSubtitle: 'as of {{dateNow}}',
        overdueAmountTitle: 'Late',
        overdueAmountSubtitle: 'as of {{dateNow}}'
      },
      actionMenu: {
        existingInvoice: 'Add existing invoice',
        quickAddInvoiceExcerpt: 'Quick add',
        importOCR: 'Import Invoice (OCR)'

      },
      controls: {
        pauseTracking: 'Stop tracking',
        resumeTracking_one: 'Track invoice',
        resumeTracking_other: 'Track invoices'
      },
      feedback: {
        trackingIsResumed_one: 'This invoice is now being tracked and will be dunned.',
        trackingIsResumed_other: 'These invoices are now tracked and will be dunned.',
        trackingIsPaused_one: 'This invoice will no longer be dunned. You can track it again at any time.',
        trackingIsPaused_other: 'These invoices will no longer be dunned. You can track them again at any time.'
      },
      columns: {
        client: 'Customer',
        customerName: 'Customer name',
        invoiceNumber: 'Invoice',
        issuedDate: 'Issued',
        amount: 'Amo. incl. taxes',
        totalIncludingTaxes: 'Total incl. taxes',
        installmentIncludingTaxes: 'Billed incl. taxes',
        amountIncludingTaxes: 'Amo. incl. taxes',
        amountExcludingTaxes: 'Amo. excl. taxes',
        currency: 'Currency',
        vat: 'TVA',
        taxesPercent: 'Tax %',
        taxesAmount: 'Total Taxes',
        dueDate: 'Due',
        status: 'Status',
        followUp: 'Next step/action'
      },
      filters: {
        filtersTitle: 'Filters',
        filtersTitleActive_one: '{{count}} Filter',
        filtersTitleActive_other: '{{count}} Filters',
        clearAllFilters_one: 'Clear filter',
        clearAllFilters_other: 'Clear filters',
        closeFilters: 'Close',
        headerOnTime: 'On Time',
        checkboxFilterBetween0And30DaysTillDue: 'Due in 0 to 30 days',
        checkboxFilterBetween31And60DaysTillDue: 'Due in 31 to 60 days',
        checkboxFilterBetween61And90DaysTillDue: 'Due in 61 to 90 days',
        checkboxFilterMoreThan90TillDue: 'Due in more than 90 days',
        headerLate: 'Late',
        checkboxFilterBetween1And30DaysOverdue: 'Due 1 to 30 days ago',
        checkboxFilterBetween31And60DaysOverdue: 'Due 31 to 60 days ago',
        checkboxFilterBetween61And90DaysOverdue: 'Due 61 to 90 days ago',
        checkboxFilterMoreThan90DaysOverdue: 'Due more than 90 days ago',
        headerAmount: 'Amount',
        checkboxFilterAmountLessThan500: 'Less than 500 {{currency}}',
        checkboxFilterAmountBetween500And5000: 'Between 500 {{currency}} and 5,000 {{currency}}',
        checkboxFilterAmountBetween5000And20000: 'Between 5,000 {{currency}} and 20,000 {{currency}}',
        checkboxFilterAmountBetween20000And75000: 'Between 20,000 {{currency}} and 75,000 {{currency}}',
        checkboxFilterAmountBetween75000And150000: 'Between 75,000 {{currency}} and 150,000 {{currency}}',
        checkboxFilterAmountMoreThan150000: 'More than 150,000 {{currency}}'


      },
      actionKind: {
        InvoiceIssued: 'Facture créée',
        InvoiceIssuance: 'Envoyer la facture',
        NoActionAtThisStage: 'Pas d\'action',
        CourtesyReminderShortlyBeforeDueDate: 'Rappel avant échéance',
        ReminderJustAfterDueDate: 'Rappel après échéance',
        ReminderShortlyAfterDueDate: '2ème rappel après échéance',
        DirectReminderSomeTimeAfterDueDate: 'Rappel direct après échéance',
        StrongLastReminderAfterDueDate: 'Addresser un recommandé',
        CollectionLitigation: 'Passer en contentieux',
        DisputeResolution: 'Litige à résoudre',
        CelebrateAndClose: 'Féliciter et fermer',
        CheckCashApplicationProposal: 'Vérifier le paiement',
        NothingAnymore: 'Plus rien à faire',
        ToBeDefined: 'A définir'
      },
      latestContact: 'Dernier contact :',
      paidAt: 'Paid at :'
    },
    onboarding: {
      back: ' Return',
      previous: 'Return to previous step',
      next: 'Continue to next step',
      signIn: 'Sign in',
      signOut: 'Sign out',
      placeholders: {
        legalStructure: 'SASU - Simplified single-member joint stock company',
        role: 'Chief Financial Officer',
        industry: 'Hotel-café-restaurant'
      },
      lists: {
        numberOfEmployees: '["0 (indie/solopreneur)","1 to 9 employees","10 to 49 employees","50 to 499 employees","from 500 to 999 employees","+ than 1000 employees"]',
        industry: '["Hotel-café-restaurant","Consulting / Marketing","Construction","Retail and e-commerce","Transportation and automotive","Other industry"]',
        role: {
          ceo: 'CEO',
          cfo: 'Chief Financial Officer',
          accountant: 'Chartered Accountant / Accountant',
          management: 'Management control',
          administrative: 'Administrative Officer',
          other: 'Other'
        },
        invoicingSoftware: '["Axonaut","Cegid","Sage","Sellsy","Quickbooks","Pennylane","Excel / Word","By hand","Xero","Other"]',
        numberOfInvoices: '["Less than 10 invoices","10 to 49 invoices","50 to 99 invoices","100 to 499 invoices","More than 500 invoices"]'
      },
      head: {
        progress: {
          step1: 'Your account',
          step2: 'Your company',
          step3: 'Let\'s go'
        }
      },
      start: {
        sidebar: {
          title: 'Get paid easier & faster',
          subTitle: 'With a Dundy account, you\'ll get:',
          bullet1: 'Unlimited monitoring of your customer invoices',
          bullet2: 'A payment portal with 0 transaction fees',
          bullet3: 'Support from our Dundy coach until you get paid'
        },
        main: {
          title: 'Let\'s get to know each other to start :)',
          form: {
            firstName: 'First Name',
            lastName: 'Last Name',
            mandatory: 'This field is mandatory'
          }
        }
      },
      signup: {
        sidebar: {
          testimony: '“Dundy has really simplified my life and saved me a ton of time tracking bills. I really recommend it!"',
          name: 'Day-Mikes Assala',
          role: 'Globe&Colos Manager',
          firstKPI: {
            title: '+6',
            description: 'Hours of admin saved'
          },
          secondKPI: {
            title: '+23%',
            description: 'Customer collections on due date'
          },
          thirdKPI: {
            title: '100%',
            description: 'Happy to invoice and get paid'
          }
        },
        main: {
          title: 'Let\'s create your Dundy account right away…',
          note: 'Your invoices and payments are sensitive information. To protect them, you need your professional email address and a strong password.',
          form: {
            email: 'Email',
            password: 'Password',
            mandatory: 'This field is mandatory',
            terms1: 'By clicking \'Continue\' you agree to our',
            terms2: 'Terms of Use',
            terms3: 'and our',
            terms4: 'privacy policy',
            passwordHint: 'Avoid passwords already used on other sites or too easy to guess.'
          }
        }
      },
      confirmEmail: {
        sidebar: {
          title: 'Why validate your email?',
          text1: 'Good question!',
          text2: 'If you take a break from creating your account, we can easily get you back on track!'
        },
        main: {
          title: 'You have mail!',
          subTitle: 'We just sent an email to your address.',
          text1: 'To validate your account, click on the button',
          text2: '«',
          text3: 'Confirm my email',
          text4: '»',
          text5: 'or copy and paste the link into your browser.',
          text6: 'You will be redirected here to finish creating your account.',
          text7: 'See you soon!'
        }
      },
      companyInfo: {
        sidebar: {
          title: 'Save time every day',
          text: 'Less managing your invoices, more time for your business.'
        },
        search: {
          button: 'Search',
          manualRegister: 'You can\'t find it, enter your information manually',
          foundNone: 'No results',
          foundOne: '1 result',
          foundMany: '{{n}} results',
          placeholder: 'Company name or siren/siret',
          filter: 'Refine your search with postal code:',
          disclaimer: 'At the moment, Dundy is only aimed at companies domiciled in France.',
          add: 'Add your company manually',
          registrationPending: 'Is your company in the process of being registered?',
          or: 'or'
        },
        list: {
          noMore: 'There are no more results'
        },
        edit: {
          choiceExisting: 'Existing business',
          choicePending: 'In progress',
          registrationPending: 'Company in process of registration',
          notAvailable: 'Not specified',
          noSirenNumber: 'No SIREN number',
          noVatNumber: 'No VAT number',
          title: 'Review your business listing and edit if necessary.',
          titleNew: 'Enter your company\'s current registration details.',
          formalName: 'Company name',
          usualName: 'Usual name (optional)',
          naf: 'Activity (NAF or APE Code)',
          nafError: 'The format must be of the form 00.00Z',
          siren: 'SIREN',
          siret: 'SIRET',
          vatInfo: 'To comply with current legislation, companies established in the European Union must include their intra-community VAT number on their invoices and commercial documents.',
          vat: 'Intra-community VAT',
          shareCapital: 'Share capital',
          legalStructure: 'Legal structure',
          street: 'Street',
          extraAddressLine: 'Address complement',
          zipCode: 'Zip Code',
          city: 'City',
          state: 'State',
          missValue: 'information manquante',
          miss: 'Fill in the missing information using the button below.',
          incorrect: 'The information is not correct?',
          edit: 'Edit information',
          cancel: 'Cancel',
          validate: 'Validate',
          errMinSize: 'Length must be at least {{n}} characters',
          errMinValue: 'The value must be at least {{v}}',
          errAPE: 'Code composed of 4 numbers followed by a letter',
          errSIRET: 'Code composed of 14 digits',
          errVAT: 'Code composed of the FR code followed by 11 digits',
          errRequired: 'Field cannot be empty'
        },
        main: {
          title: 'Search and select your company.',
          form: {
            country: 'In which country is your company established?\n',
            countryNote: 'At this time, Dundy only caters to companies operating from France.',
            companyName: 'Company Name\n',
            balloon: {
              text1: 'You can find it easily at',
              text2: '(search by name and locality) or on the official documents of your company.'
            },
            legalIdentification: 'SIREN',
            legalIdentificationNote: 'This is your 9-digit company identification number. The 14-digit SIRET is also accepted.',
            mandatory: 'This field is mandatory'
          }
        }
      },
      companyInfoPlus: {
        sidebar: {
          title: 'No Hidden Fees',
          text: 'You do not pay a subscription for Dundy and your customers\' transfers are free. For real !'
        },

        main: {
          title: 'Tell us more about your role at ',
          form: {
            street: 'Head office address',
            postCode: 'ZIP / Postal code',
            city: 'City',
            role: 'What is your role within {{company}}?',
            phoneNumber: 'What\'s the number we can reach you at?',
            phoneNumberPlaceholder: '+0 (000) 000-0000',
            customRole: 'What\'s your official title?',
            activity: 'What is the industry of {{company}}?',
            legalIdentification: 'SIREN',
            legalIdentificationNote: 'This is your 9-digit company identification number. The 14-digit SIRET is also accepted.',
            mandatory: 'This field is mandatory',
            back: 'BACK'
          }
        }
      },
      companyInfoFinal: {
        sidebar: {
          title: 'Get paid faster',
          text: 'Discover the tranquility of being paid on time.'
        },
        main: {
          title: 'Almost there!',
          note: 'A few follow-up questions to help us set up your Dundy account.',
          form: {
            monthlyInvoices: 'How many invoices do you issue per month?',
            invoicingSoftware: 'What invoicing software do you use?\n',
            customInvoicingSoftware: 'Fill in the name of your invoicing software',
            numberOfEmployees: 'How many employees does {{company}} have?',
            mandatory: 'This field is mandatory',
            back: 'BACK'
          }
        }
      },
      appIntro: {
        sidebar: {
          title: 'In a few seconds, you will be able to:',
          bullet1: 'Add your first invoice on Dundy',
          bullet2: 'Access the follow-up steps to follow the processing of this invoice',
          bullet3: 'Send a payment link to your customer to pay by a free transfer for you and your customer!'
        },
        main: {
          title: 'Everything is ready to start using Dundy',
          button: 'ACCESS MY ACCOUNT'
        }
      }
    },
    sidebar: {
      sections: {
        driveBusiness: 'Drive my business',
        getPaid: 'Get Paid',
        manageStructure: 'Manage company'
      }
    },
    settings: {
      title: 'Settings',
      profile: {
        title: 'Profile settings',
        subtitle: 'Edit your basic information, set a new password.'
      },
      company: {
        title: 'Company settings',
        subtitle: 'Edit your business name, address and legal information.'
      },
      bank: {
        title: 'Bank account',
        subtitle: 'Review and edit your corporate bank account access.'
      }
    },
    team: {
      title: 'Team',
      manage: {
        title: 'Team Members & Contacts',
        subtitle: 'Set permissions for your team members and contacts.',
        add: 'Add Member or Contact'
      },
      invite: {
        title: 'Invite Team Member',
        subtitle: 'The more, the merri... The more cash!'
      }
    },
    addCustomer: {
      error: 'Error adding client',
      title: 'Add new client',
      cancel: 'Cancel',
      save: 'Save client',
      missingInfoTitle: 'Some things are missing :)'
    },
    editCustomer: {
      error: 'Update failed',
      title: 'Edit client',
      cancel: 'Cancel',
      save: 'Save changes',
      next: 'Create new contact',
      companyLinkTitle: 'Company Details',
      contactsLinkTitle: 'Contacts',
      newCompanyCaption: 'Start by filling in the details of the company you are creating.',
      existingCompanyCaption: 'Edit the information for the client company.',
      contactsCaption: 'Manage the names and information of your contacts.',
      backLink: 'Back to client list',
      loading: 'Working...',
      saveContact: 'Save Contact',
      deleteCustomer: 'Delete client',
      deleteCustomerTitle: 'Delete this client',
      deleteCustomerCaption1: 'Once you delete this client, there is no going back.',
      deleteCustomerCaption2: 'Please be careful.',
      deleteConfirmation: 'Are you certain you want to delete this customer?',
      contactAdded: 'Contact Added',
      contactUpdated: 'Contact Updated',
      customerAdded: 'Customer Added',
      customerUpdated: 'Customer Updated'
    },
    customerOverview: {
      previousPayment: 'Previous payment',
      nextPayment: 'Next payment',
      averagePayDay: 'Average Pay Day',
      overdue: 'Overdue',
      onTime: 'On Time',
      paid: 'Paid',
      latestContact: 'Recent exchange',
      upcomingTasks: 'Upcoming Tasks',
      edit: 'Edit client',
      viewInvoicesLink: 'View Invoices',
      timelineTitle: 'Timeline',
      contactLabel_one: 'Your contact',
      contactLabel_other: 'Your contacts',
      otherContactLabel_one: 'and {{count}} other contact',
      otherContactLabel_other: 'and {{count}} other contacts',
      upcomingAction: 'Upcoming dunning',
      invoiceNumber: 'Invoice n°',
      dueDate: 'Due Date',
      dunningStep: 'Action Step',
      status: 'Status',
      amount: 'Total Amount',
      selectTab: 'Select a tab',
      addContact: 'Add a contact'
    },
    customerList: {
      title: 'Customers',
      countDescription_one: '{{count}} customer',
      countDescription_more: '{{count}} customers',
      empty: 'Start by adding a new customer by clicking on the "New Customer" button',
      search: 'Search for companies by name or contact',
      add: 'New Customer',
      edit: 'Edit customer',
      delete: 'Delete customer',
      table: {
        name: 'Name',
        type: 'Type',
        overdue: 'Late',
        onTime: 'On time',
        actions: 'Actions',
        french: 'French company',
        inEU: 'European company',
        noEU: 'Foreign company',
        privateIndividual: 'Private Individual'
      },  
      modal: {
        deleteHeading: 'Confirm deletion ?',
        deletePrimaryButton: 'Delete',
        deleteSecondaryButton: 'Cancel',
        deleteInfo: 'Do you really want to delete this customer ? All data will be permanently deleted from our servers. This action cannot be undone.'
      },
      feedback: {
        delete: 'The customer has been deleted'
      }
    },
    customerManage: {
      page: {
        newClient: 'New client',
        editClient: 'Edit Client'
      },
      form: {
        templatesTitle: 'Reminder Preferences',
        templatesLanguage: 'Communication Language',
        templatesEdition: 'Editing Reminder Templates',
        contactsTitle: 'Contacts',
        selectClientType: 'Select a client type:',
        frenchPrivateCompany: 'French Company',
        frenchPrivateCompanyCopy: 'Professional client whose head office is located in France',
        foreignPrivateCompany: 'Foreign Company',
        foreignPrivateCompanyCopy: 'Professional client whose head office is located outside of France',
        individual: 'Individual',
        individualCopy: 'Physical person residing in France or abroad',
        individualClient: 'Individual client',
        professionalClient: 'Professional client',
        saveClient: 'Save',
        deleteClient: 'Delete this client',
        deleteContact: 'Delete this client',
        address: 'Address',
        asideNoteFrenchCompany: 'To add a French company, <strong>enter its legal name</strong>. Dundy automatically retrieves the <strong>company file</strong> from the official data of INSEE and INPI.',
        asideNoteOther: 'Enter the <strong>information of your client</strong> using the provided form. Verify the <strong>data</strong> you enter, as it <strong>will be used to generate invoices and perform client follow-ups</strong>.'
      },
      modal: {
        customerDeleteInfo: 'Do you really want to delete this customer ? All data will be permanently deleted from our servers. This action cannot be undone.',
        contactDeleteInfo: 'Do you really want to delete this contact ? All data will be permanently deleted from our servers. This action cannot be undone.'
      },
      feedback: {
        customerDelete: 'The customer has been deleted',
        contactDelete: 'The contact has been deleted',
        customerDeleteError: 'Customer could not be deleted',
        contactDeleteError: 'Contact could not be deleted'
      }
    },
    listCustomers: {
      error: 'Error deleting client',
      columns: {
        client: 'Client',
        numberOfInvoices: '# of Invoices',
        outstandingAmount: 'Outstanding amount',
        accountStatus: 'Account Status'
      },
      title: 'Clients',
      add: 'Add new client',
      delete: 'Delete client',
      search: {
        placeholder: 'Search for companies by name or contact'
      },
      pagination: {
        label1: 'Showing',
        label2: 'to',
        label3: 'of',
        label4: 'clients'
      },
      due: 'due',
      overdue: 'overdue',
      empty: {
        title: 'No clients yet',
        first_paragraph: 'Start with creating a new client.'
      }
    },
    listContacts: {
      deleteConfirmation: 'Are you certain you want to delete',
      delete: 'Delete',
      cancel: 'No, cancel',
      emptyText: 'Start by creating a contact by clicking the "Add Contact" button.',
      addContactText: 'Create a contact for this company by clicking on the button below:'
    },
    companyInfo: {
      legalIdPlaceholder: 'Legal Identification Number',
      errorContinue: 'Request failed.',
      title: 'Tell us a bit more about the company you work for',
      company: 'Company name',
      address: 'Address',
      city: 'City',
      zipCode: 'ZIP / Postal code',
      country: 'Country',
      fillLegalId: 'Fill in the legal identification number for your company',
      continue: 'Continue'
    },
    companyLegalIdentification: {
      europeanVAT: 'Intra-community European VAT number',
      duns: 'DUNS',
      sirenSiret: 'SIREN/SIRET'
    },
    confirmEmail: {
      title: 'Check your mail inbox!',
      sentEmail: 'We just sent you an email to confirm your address.',
      validate: {
        one: 'Please validate by clicking on the button «',
        two: ' Confirm my email ',
        three: '» or use the link.'
      },
      bringBack: 'This will bring you back here to finish up your onboarding.',
      seeYou: 'See you in a bit!'
    },
    connectBank: {
      title: 'Now, let’s connect your bank account',
      why: {
        title: 'Why do we need this?',
        one: 'To make Dundy work, we use a ',
        two: 'read-only access',
        three: ' to monitor payments made to you by clients.'
      },
      secure: {
        title: 'Is it secure?',
        one: 'We ',
        two: ' do not store ',
        three:
          ' your credentials. Everything goes through our OpenBanking partners using ',
        four: ' AES-256 bit encryption '
      },
      note: 'Our system was designed from the get-go to guarantee the safety of your information. Security is our top priority.',
      learnMore: 'LEARN MORE',
      continue: 'Continue'
    },
    registerType: {
      alert: {
        header: 'Your Email is now confirmed.',
        message: 'You are 5 minutes away from experiencing Dundy!'
      },
      error: 'Update failed.',
      title:
        'Are you registering for your own company or the company you work for?',
      boss: 'My own company / myself',
      employee: 'The company I work for',
      continue: 'Continue'
    },
    selectAccount: {
      error: 'Selection failed.',
      title: 'Select your account',
      submit: 'Save my selection',
      note: 'Our system was designed from the get-go to guarantee the safety of your information. Security is our top priority.',
      learnMore: 'LEARN MORE',
      continue: 'Continue',
      cancel: 'Cancel',
      noResults: 'There are no accounts selectable.',
      bankCaption1: 'You are already connected to your "{{bankName}}" bank.',
      bankCaption2: 'Just enter the bank account of your choice.',
      chooseText: 'Choose the bank account where customers pay you:'
    },

    start: {
      title: {
        one: 'Let’s get started!',
        two: 'Just a few quick questions and we’ll get you set up.'
      },
      firstName: {
        placeholder: 'First name',
        error: 'First name must be at least two characters long'
      },
      lastName: {
        placeholder: 'Last name',
        error: 'Last name must be at least two characters long'
      },
      continue: 'Continue'
    },
    addBank: {
      info: {
        one: 'Payment is back!',
        two: 'You can now let your clients pay you in one-click for free.',
        three: 'Start now'
      }
    },
    bankConnectionModal: {
      title: 'Add an account',
      title1: 'How does it work?',
      title2: 'Is it secure?',
      bullet1: {
        text1: 'We will',
        text2: 'redirect you to our partner',
        text3: 'Open-Banking, Bridge now.'
      },
      bullet2: {
        text1: 'You can',
        text2: 'just follow the steps',
        text3: 'to connect your bank account.'
      },
      bullet3: {
        text1: 'When the process is complete, you',
        text2: 'automatically return to this page.'
      },
      bullet4: {
        text1: 'We use',
        text2: 'read-only access',
        text3: 'to check the payments that your customers send to you.'
      },
      bullet5: {
        text1: 'No operation',
        text2: 'can be done without',
        text3: 'your explicit consent.'
      },
      bullet6: {
        text1: 'We do not store your credentials.'
      },
      bullet7: {
        text1: 'Login to your account is fully supported by our partner Bridge through secure encryption.'
      },
      submit: 'Connect my bank account'
    },
    editBank: {
      title: 'Bank Account',
      subtitle_zero: 'You have no connected bank accounts.',
      subtitle_one: 'You have {{count}} connected bank account.',
      subtitle_other: 'You have {{count}} connected bank accounts.',
      switchAccount: 'Switch Account',
      addBankAccount: 'Replace bank account',
      activeBank: 'Active',
      deleteBankAccount: 'Remove bank account',
      detailsSaved: 'Bank details saved successfully',
      clear: 'Clear',
      save: 'Save',
      incompleteDetails: 'Please provide all the account details.',
      connectionRemoved: 'Bank Connection Removed.',
      warning: {
        one: 'Action required!',
        two: ' Connect to your bank account to keep enjoying Dundy'
      },
      connected: {
        title: 'You are connected to your bank account {{accountNumber}} from',
        header:
          'If you want to switch your bank account for a new one, follow the steps below:',
        clickConnect:
          'Click « Connect to new account » to authorize us to connect to your new bank account.',
        connect: 'Connect to new account'
      },
      disconnected: {
        title: 'You are no longer connected to your bank account',
        header:
          'Due to the way our OpenBanking partner works, we occasionally have to ask you to connect again to your account. This is one of those times. We apologize for the inconvenience while we work on a more reliable system.',
        clickConnect:
          'Click « Connect to my bank » to authorize us to connect to your bank account.',
        connect: 'Connect to my bank'
      },
      intro: 'Connecting your bank account allows us to keep track of your payments.\n' +
        'Choose one of the options below.',
      connect: {
        title: 'Add new bank account',
        description: 'Connect to your new bank and select the account from which you will receive payments.'
      },
      select: {
        title: 'Select your account',
        description: 'Switch between your accounts to select your main account.'
      },
      dontStore1: 'We do not view or store your credentials.',
      dontStore2: 'Everything goes through our OpenBanking partners using AES-256 bit encryption.',
      note: 'Our system was designed from the get-go to guarantee the safety of your information. Security is our top priority.',
      learnMore: 'LEARN MORE',
      whyConnectTitle: 'Why connect your bank account?',
      whyConnect: 'You will have access to:',
      whyConnectSubtitle: 'This will give you access to:',
      whyConnectBullet1: 'Take advantage of the automatic filling of your banking information.',
      whyConnectBullet2: 'Get a payment link to get paid quickly and for free!',
      whyConnectBullet3: 'Discover automated bank reconciliation between your open invoices and your incoming transactions',
      whyConnectButton: 'Connect my bank account',
      privacyNotice: 'We don\'t <strong>sell your credentials</strong> and we do <strong>not sell your data</strong>. Everything is secured by our service provider Bridge for Bankin.',
      privacyLink: 'More info',
      orManualOption: 'OR',
      manualOptionTitle: 'Manually enter your bank account information.',
      manualOptionSubtitle: 'This information will be used by your customers who wish to pay you by manual transfer.',
      bankAccountName: 'Account name',
      bankName: 'Bank:',
      bankNamePlaceholder: 'Name of your bank',
      bankIbanNumber: 'IBAN:',
      bankIbanPlaceholder: 'FR760000000000000',
      bankBIC: 'BIC:',
      bankBICPlaceholder: 'XXXXX',
      addBICCode: 'Please enter the BIC code corresponding to your bank.',
      addIBAN: 'Please enter the IBAN corresponding to your bank account.',
      addBankName: 'Please enter the name of your bank.',
      headingBankDetails: 'Your bank account details',
      subHeadingBankDetails: 'The information below will be communicated to your customers who wish to pay by bank transfer from their own banking interface (by manual transfer).',
      bankDetailsNotice: 'Check the information carefully to avoid typing errors. Your customers will use these bank details to pay you by manual transfer.'

    },
    companyEdit: {
      title: 'My company',
      titleCopy: 'Fill in your company details',
      error: 'Update failed.',
      company: 'Company name',
      address: {
        label: 'Address',
        error: 'At least one character'
      },
      city: {
        label: 'City',
        error: 'At least one character'
      },
      zipCode: {
        label: 'ZIP / Postal code',
        error: 'At least one character'
      },
      closingDate: 'closingDate',
      state: 'State / Department',
      country: 'Country',
      legalId: 'Legal identification',
      cancel: 'Cancel',
      save: 'Save changes',
      updateSuccess: 'Company details updated.',
      logoLabel: 'Company logo',
      logoSubtitle: 'Update your company logo. It will be displayed in your dunning emails.',
      clickUpload: 'Click to upload',
      uploadLimitations: 'PNG, JPG or GIF (MAX. 1200x1200px, Size limited to 1MB)',
      uploadHelp: 'To change the file, click on the upload button, the file information or the file preview.',
      feedbackAllowedTypes: 'Allowed file types are {{count}}',
      feedbackSizeLimit: 'Maximum file size is {{count}} and your file is {{yourfile}}.',
      feedbackWidthAndHeight: 'Height and Width must not exceed 1200px.',
      selectNewLogo: 'Choose new logo',
      removeLogo: 'Remove logo',
      resetLogo: 'Reset logo',
      reFramePicture: 'Re-frame the image',
      resizePicture: 'Resize the picture by dragging the selection rectangle.',
      confirmReFraming: 'Confirm',
      cancelReFraming: 'Cancel'
    },
    editPassword: {
      error: 'Update failed.',
      title: 'SECURITY',
      currentPassword: {
        label: 'Current password',
        error: 'Please enter a valid password'
      },
      newPassword: {
        label: 'New password',
        error: 'Please enter a valid password',
        noMatch: 'Passwords do not match'
      },
      cancel: 'Cancel',
      save: 'Save changes'
    },
    profileEdit: {
      error: 'Update failed.',
      account: 'My account',
      accountCopy: 'Review the information for this account and make sure everything is up to date.',
      personalInformation: 'General information',
      firstName: {
        label: 'First name',
        error: 'At least two characters'
      },
      lastName: {
        label: 'Last name',
        error: 'At least two characters'
      },
      email: {
        label: 'Email address',
        verified: 'Verified email',
        notVerified: 'Email not verified'
      },
      phoneNumber: {
        label: 'Phone number',
        error: 'Fill in a valid phone number'
      },
      officePhone: {
        label: 'Office phone number',
        error: 'Fill in a valid phone number'
      },
      mobilePhone: {
        label: 'Mobile phone number',
        error: 'Fill in a valid phone number'
      },
      role: {
        label: 'Role inside {{company}}'
      },
      customRole: {
        label: 'Fill in your official title'
      },
      language: {
        heading: 'Interface settings',
        copy: 'The default language for buttons, titles, and text displayed on Dundy for your account.',
        label: 'Preferred language'
      },
      security: 'Security',
      securityCopy: 'To update your password, please follow the procedure for password recovery.',
      current: 'Current password',
      reset: 'Reset password',
      cancel: 'Cancel',
      save: 'Save changes',
      updateSuccess: 'Profile details updated.'
    },
    editMember: {
      cancel: 'Cancel',
      save: 'Save changes'
    },
    inviteMember: {
      alert: {
        header: 'An invitation',
        message: 'to join the workspace has been succesfully sent to '
      },
      invite: 'Invite user',
      error: {
        one: 'It looks like something went wrong on our end. Please send us an email at ',
        two: 'help@dundy.co',
        three: ' so we can onboard your team mate.'
      }
    },
    listMembers: {
      error: 'Update failed.',
      title: 'TEAM',
      subtitle: {
        one_one: 'You have {{count}} teammate on your team.',
        one_other: 'You have {{count}} teammates on your team.'
      }
    },
    customerForm: {
      title: 'COMPANY DETAILS',
      company: {
        label: 'Company name',
        usualName: 'Usual name',
        isPrivateIndividual: 'Private individual customer',
        isProfessional: 'Business customer',
        error: 'At least one character'
      },
      address: {
        label: 'Address',
        extraAddressLine: 'Extra address line',
        error: 'At least one character'
      },
      city: {
        label: 'City',
        error: 'At least one character'
      },
      zipCode: {
        label: 'Zipcode',
        error: 'At least one character'
      },
      state: 'State / Department',
      country: 'Country',
      legalId: {
        label: 'Legal identification',
        error: 'At least one character'
      },
      vat: {
        label: 'Intra-Community VAT',
        error: 'Empty or incorrect VAT number'
      },
      email: {
        label: 'Email',
        error: 'Please fill in a valid email'
      },
      firstName: {
        label: 'First Name',
        error: 'At least one character'
      },
      lastName: {
        label: 'Last Name',
        error: 'At least one character'
      },
      phoneNumber: {
        label: 'Phone',
        error: 'Fill in a phone number'
      },
      officePhone: {
        label: 'Office Phone',
        error: 'Fill in an office phone number'
      },
      mobilePhone: {
        label: 'Mobile Phone',
        error: 'Fill in a mobile phone number'
      },
      position: {
        label: 'Position',
        error: 'Fill your contact\'s position'
      },
      department: {
        label: 'Department',
        error: 'Fill your contact\'s department'
      }
    },
    customerLabel: {
      edit: 'Edit'
    },
    addInvoice: {
      invoiceDescription: 'Description',
      error: 'Error adding invoice',
      add: 'Add invoice',
      quickAdd: 'Quick add'
    },
    invoiceStatus: {
      creditNote: 'Credit Note',
      dataComplete: 'Finalize',
      daysLate_one: ' day late',
      daysLate_other: ' days late',
      deleted: 'Deleted',
      draft: 'Draft',
      draftCreditNote: 'Credit note draft',
      draftInvoice: 'Invoice draft',
      draftPurchaseOrder: 'Purchase order draft',
      draftQuote: 'Quote draft',
      invoice: 'Invoice',
      onTime: 'On time',
      paid: 'Paid',
      pendingVoidedCreditNote: 'Pending voided credit note',
      pendingVoidedInvoice: 'Pending voided invoice',
      pendingVoidedPurchaseOrder: 'Pending voided purchase order',
      pendingVoidedQuote: 'Pending voided quote',
      pendingVoidingCreditNote: 'Pending voiding credit note',
      pendingVoidingInvoice: 'Pending voiding invoice',
      pendingVoidingPurchaseOrder: 'Pending voiding purchase order',
      pendingVoidingQuote: 'Pending voiding quote',
      purchaseOrder: 'Purchase order',
      quote: 'Quote',
      startTracking: 'Start tracking',
      tbd: 'tbd',
      voidedCreditNote: 'Voided credit note',
      voidedInvoice: 'Voided invoice',
      voidedPendingInvoice: 'Voided pending invoice',
      voidedPurchaseOrder: 'Voided purchase order',
      voidedQuote: 'Voided quote',
      voidedVoidingCreditNote: 'Voided voiding credit note',
      voidedVoidingInvoice: 'Voided voiding invoice',
      voidedVoidingPurchaseOrder: 'Voided voiding purchase order',
      voidedVoidingQuote: 'Voided voiding quote',
      voidingCreditNote: 'Voiding credit note',
      voidingInvoice: 'Voiding invoice',
      voidingPendingFinalize: 'Voiding pending finalize',
      voidingPurchaseOrder: 'Voiding purchase order',
      voidingQuote: 'Voiding quote',
      wip: 'wip'
    },
    markPaidOrUnpaidInvoice: {
      error: 'Error changing status',
      markPaid: 'Mark as paid',
      markUnpaid: 'Mark as unpaid'
    },
    memberForm: {
      titleRole: 'USER ROLE',
      admin: 'Administrator',
      contributor: 'Contributor',
      noteAdmin:
        'Administrators have full access to your Dundy account. This includes clients, bank details, payments and settings. Admins can also add other admins and contributors.',
      noteContributor:
        'Contributors can view and close tasks, they can add, delete and view invoices. They have access to their own settings.',
      titleDetails: 'USER DETAILS',
      firstName: {
        label: 'First name',
        error: 'At least two characters'
      },
      lastName: {
        label: 'Last name',
        error: 'At least two characters'
      },
      email: {
        label: 'Email address',
        error: 'Please enter a valid email'
      }
    },
    signUpForm: {
      error: 'Sign up failed',
      title: {
        one: 'Nice to meet you, ',
        two: 'Let\'s start by creating your account'
      },
      email: {
        placeholder: 'Email',
        error: 'Please enter a valid email'
      },
      password: {
        placeholder: 'Password',
        error: 'Please enter a valid password'
      },
      note: {
        title: 'Your password must have:',
        one: '12 or more characters',
        two: 'Uppercase and lowercase letters',
        three: 'At least one number',
        four: 'At least one special character'
      },
      continue: 'Continue',
      footer: {
        one: 'By signing up you agree to Dundy’s',
        two: 'Terms of service',
        three: 'and',
        four: 'Privacy policy'
      }
    },
    searchBar: {
      placeholder: 'Search...'
    },
    smallSelect: {
      placeholder: 'Select...'
    },
    bigSelect: {
      placeholder: 'Select...'
    },
    clients: {
      heading: 'Contacts',
      headingContacts_one: 'There is {{count}} saved contact for this company.',
      headingContacts_other: 'There are {{count}} saved contacts for this company.',
      addContact: 'Add a contact',
      addOrEditContact: 'Add or edit a contact for this company.',
      companyHeading: 'Company details',
      modalHeading: 'Client details',
      saveClient: 'Save client'
    },
    agingBalance: {
      title: 'Aging Balance',
      search: {
        placeholder: 'Search for customer'
      },
      header: {
        due: 'Due (€)',
        overdue: 'Overdue (€)',
        client: 'Client',
        leftToPay: 'Outstanding Balance',
        above90Days: '> 90 Days',
        From90To61Days: '90-61 Days',
        From60To31Days: '60-31 Days',
        From31To1Day: '30-1 Day',
        From0To30Days: '0-30 Days',
        From031To60Days: '31-60 Days',
        From61To90Days: '61-90 Days',
        MoreThan90DaysAway: '> 90 Days',
        total: 'Total'
      },
      sideNote: {
        title: 'Some Explanations on Aging Balance',
        copy_FirstLine: 'The aging balance allows you to track the money owed to you by your clients, classifying them based on the date they are expected to pay.',
        copy_SecondLine: 'The amounts are calculated considering finalized and followed invoices.'
      }
    },
    todo: {
      stats: {
        totalOutstanding: 'Total Outstanding Amount',
        totalOutstandingTooltip: 'The total outstanding amount is the total of all sales invoices issued to date that are awaiting payment.',
        dueAmount: 'Non-due Amount',
        dueAmountTooltip: 'The non-due amount is the money you are to receive from your sales, but is not yet due according to the dates stated on the invoices.',
        overdueAmount: 'Overdue Amount',
        overdueAmountTooltip: 'The overdue amount is the money that you should have already received from your sales as the due date of the invoices has been reached or exceeded.',
        dsoTooltip: 'DSO (Days Sales Outstanding) is a financial measure that indicates how many\n' +
          'days, on average, a company takes to collect its receivables after\n' +
          'a sale. The higher the DSO, the more time it takes to collect this\n' +
          'money.',
        dsoUnit_one: 'day',
        dsoUnit_other: 'days',
        dsoInvalid: 'unavailable'
      },
      firstEmptyState: {
        createInvoiceDrive: 'Start by creating an invoice',
        createInvoiceSubtext: 'Add your first invoice to start tracking it and get paid faster.',
        createInvoiceButton: 'Create Invoice',
        createCustomerDrive: 'Start by adding a customer and a contact',
        createCustomerSubtext: 'Add your first customer and his contact to send him an invoice.',
        createCustomerButton: 'Add customer and first contact'
      },
      nav_todo: {
        today: 'Today',
        done: 'done',
        upcoming: 'upcoming',
        action_one: 'Action',
        action_other: 'Actions',
        clientsOverdueMaj_one: 'Client overdue',
        clientsOverdueMaj_other: 'Clients overdue',
        agingBalance: 'Aging balance'
      },
      account_position: {
        awaitingPayment: 'Awaiting Payment',
        amountOverdue: 'Overdue',
        amountOnTime: 'On Time',
        amountDue: 'Total Amount to Collect',
        clientsOverdue_one: 'Client overdue',
        clientsOverdue_other: 'Clients overdue',
        paymentsConfirm_one: 'payment to confirm',
        paymentsConfirm_other: 'payments to confirm',
        outstandingInvoices_one: 'Outstanding invoice',
        outstandingInvoices_other: 'Outstanding invoices',
        lateInvoices_one: 'late invoice',
        lateInvoices_other: 'late invoices',
        newClients_one: 'New client',
        newClients_other: 'New clients'
      },
      tips_sidebar: {
        title: 'Tips & Advice',
        stayAmicable: 'Most customers <strong>intend to pay their\n' +
          ' invoice</strong>.<br><br>\n' +
          'Even when your client is late, <strong>keep a friendly tone</strong>. If you\'re\n' +
          ' frustrated and it starts to show, it could backfire and bring your\n' +
          ' customer to delay payment even further.<br><br> A healthy and courteous customer relationship will\n' +
          ' ensure you keep a good reputation and retain your customer.'

      },
      bank_sidebar: {
        title: 'Connect your bank account to get paid',
        listFirst: 'Take advantage of the automatic filling of your banking information.',
        listSecond: 'Get a payment link to get paid quickly and for free!',
        listThird: 'Discover automated bank reconciliation between your open invoices and your incoming transactions.',
        connectAccount: 'Configure my account',
        privacyNotice: 'We do not see your credentials and we do not sell your data. Everything is secured by our service provider Bridge for Bankin.',
        privacyLink: 'More info'
      },
      item: {
        type: {
          needApproval: 'need your approval'
        },
        time: {
          due_today: 'due today',
          due_one: 'due in {{count}} day',
          due_other: 'due in {{count}} days',
          overdue_one: 'overdue by {{count}} day',
          overdue_other: 'overdue by {{count}} days'
        },
        status: {
          newPayments_one: 'Payment',
          newPayments_other: 'Payments',
          dispute_one: 'Invoice is disputed',
          dispute_other: 'Invoices are disputed',
          firstReminder: '1st reminder',
          secondReminder: '2nd reminder',
          lastCall: 'Last call',
          legalAction: 'Legal action',
          onTime: 'On time',
          critical: 'Critical'
        },
        naturalLanguageByMessageKind: {
          InvoiceIssuance: 'Send invoice {{invoiceNumbers}} to {{contactName}} at {{customerName}}',
          Email: 'Email {{contactName}} at {{customerName}}',
          PhoneCall: 'Call {{contactName}} at {{customerName}}',
          EmailAndRegularPostMail: 'Send formal notice by post to {{customerName}}',
          LawyerAndOtherLegalServices: 'Consider legal action against {{customerName}}'
        }
      }
    },
    todoItem: {
      errorEmpty: 'There is no Todo item with the provided ID.',
      amountDueWithTaxes: 'Amount inc. Taxes',
      amountPaid: 'Total Paid'
    },
    history: {
      kind: {
        InvoiceIssuance: {
          timelineState: 'Send freshly issued invoice',
          timelineDoneState: 'Issuance Reminder sent',
          timelineDoneCopy: 'Created on {{dateDone}} in Dundy'
        },
        InvoiceIssued: {
          timelineDoneState: 'Invoice issued',
          timelineDoneCopy: 'Created on {{dateDone}} in Dundy'
        },
        CourtesyReminderShortlyBeforeDueDate: {
          timelineState: 'Deadline reminder to send',
          timelineDoneState: 'Deadline notice email sent to customer',
          timelineDoneCopy: 'Sent {{dateDone}}'
        },
        InvoicePastDue: {
          timelineState: 'Invoice overdue',
          timelineCopy: 'The due date was {{date}}',
          timelineDoneState: 'Overdue notice email sent to customer',
          timelineDoneCopy: 'Sent {{dateDone}}'
        },
        ReminderJustAfterDueDate: {
          timelineState: 'First reminder to send',
          timelineDoneState: 'First reminder email sent to client',
          timelineDoneCopy: 'Sent {{dateDone}}'
        },
        ReminderShortlyAfterDueDate: {
          timelineState: 'Second reminder to send',
          timelineDoneState: 'Second reminder email sent to client',
          timelineDoneCopy: 'Sent {{dateDone}}'
        },
        DirectReminderSomeTimeAfterDueDate: {
          timelineState: 'Third Reminder to be sent',
          timelineDoneState: 'Third Reminder email sent to customer',
          timelineDoneCopy: 'Sent {{dateDone}}'
        },
        StrongLastReminderAfterDueDate: {
          timelineState: 'Formal Notice to be sent',
          timelineDoneState: 'Formal Notice sent to customer',
          timelineDoneCopy: 'Sent {{dateDone}}'
        },
        CollectionLitigation: {
          timelineState: 'Requiring legal action',
          timelineDoneState: 'Resolved or Closed',
          timelineDoneCopy: 'Resolved {{dateDone}}'
        },
        DisputeResolution: {
          timelineState: 'Requiring dispute resolution',
          timelineDoneState: 'Dispute resolved',
          timelineDoneCopy: 'Resolved {{dateDone}}'
        }
      }
    },
    todoAction: {
      titleInvoice: 'Envoi de facture',
      titleReminder: 'Envoi de rappel',
      dunningMeansTab: {
        email: 'Email',
        phoneCall: 'Phone call',
        postalLetter: 'Postal letter',
        legalAction: 'Legal action',
        information: 'Information'
      },
      noAction: 'No action planned',
      markTodoDone: 'MARK TASK AS DONE',
      markTodoDoneSuccess: 'Todo marked as completed.',
      sendReminder: 'Send reminder',
      sendingReminder: 'Sending',
      emailTab: 'Email',
      callTab: 'Phone',
      letterTab: 'Postal Letter',
      escalationTab: 'Escalation',
      invoiceLabel: 'Invoice',
      backLink: 'Back to dashboard',
      fromLabel: 'From:',
      toLabel: 'To:',
      ccLabel: 'CC:',
      bccLabel: 'BCC:',
      subjectLabel: 'Subject:',
      timelineTitle: 'Timeline',
      copySuccess: '{{area}} copied to clipboard.',
      contentLocked: 'Content is locked. To edit this content, fill in the missing information above.',
      includePDF: 'Include PDF as attachment',
      includePaymentLink: 'Include payment link',
      missingBankDetailNotice: {
        header: 'Missing bank details!',
        copy: 'Fill in your bank details so that your customers can pay you via wire transfer.',
        button: 'Review your bank details'
      },
      missingContactNotice: {
        header: 'Missing contact information !',
        copy: 'Review the contact information before sending the email.',
        button: 'Review your contact\'s information'
      },

      resetBodyMessage: 'Reset to default template',
      copyExplainerPhoneCall: 'Call your client and follow the script below. Don\'t forget to write down all the context and answers they give you.',
      emailSubject: 'U-Trade: Your overdue invoices',
      emailFrom: 'from',
      copy: 'Copy',
      workflowPausedText1: 'This client workflow is paused.',
      workflowPausedText2: 'To resume dunning and see the next action for this client, click « Resume worfklow ».',
      resumeWorkflow: 'Resume workflow',
      pauseWorkflow: 'Pause workflow',
      paymentLink: 'Please click on the following link to download and initiate payment for your invoices.<br/><br/><a target="_blank" href="{{paymentLink}}" style="color: #F47663;">{{paymentLink}}</a>',
      paymentDescription: 'Invoice {{invoiceNumber}}',
      actionValidateToday: 'Validate this action today',
      attachedInvoiceText: '<p>Attached is a copy of the invoice. If you have any questions concerning this invoice, I will be happy to answer.</p><br/>',
      baseActionEmailItemBody: {
        yourInvoice: 'Your invoice from',
        goodMorning: 'Hello',
        hereIs: 'Please find attached your <span style="font-weight:600; color: #1F2533">invoice #{{invoiceNumber}}</span> from <span style="font-weight:600; color: #1F2533">{{senderCompanyName}}</span>:',
        invoiceAvailable: 'Your <span style="font-weight:600; color: #1F2533">invoice #{{invoiceNumber}}</span> from <span style="font-weight:600; color: #1F2533">{{senderCompanyName}}</span> is available:',
        invoiceNumber: 'invoice number',
        issuedFor: 'issued by',
        company: 'company',
        amount: 'Amount',
        ttc: 'TTC',
        dueDate: 'Due Date',
        objection: 'If this invoice is the subject of a dispute, please indicate it without delay.',
        bankDetails: 'Please click on the following link to initiate payment of this invoice by bank transfer',
        makePayment: 'Make payment',
        bankDetailsTitle: 'You can make a transfer manually using the following information',
        bank: 'Bank',
        iban: 'IBAN',
        bic: 'BIC',
        reference: 'Reference',
        thanks: 'Please include the reference number in your wire transfer information so your payment is correctly identified.',
        regards: 'Have a great day,',
        alreadyPaid: '<p style="font-weight:400;color:#1F2533;font:14px/24px Arial, Helvetica-Light, sans-serif;">If payment has already been issued, please ignore this reminder.</p>',
        thoughtfully: 'Kind regards'
      },
      actionKind: {
        InvoiceIssuance: {
          explainerIconName: 'icon-bulb',
          explainerTitle: 'Why send this email?',
          explainerText: `
                        <br/>
                        <p>Sending the invoice to your customer as soon as it is issued allows you to ensure that the latter will pay you as soon as possible.</p>
                        <br/>
                        <p>It is also necessary in order to start monitoring your invoice and the constitution of a possible litigation file in the right way.</p>
                        <br/>
                        <p>Finally, it's a good habit to be rigorous on this point.</p>
                        <br/>
                    `,
          subject: 'Your invoice number {{invoiceNumber}} from {{senderCompanyName}} is available',
          itemBody: {
            title: 'INVOICE ISSUE'
          }
        },
        CourtesyReminderShortlyBeforeDueDate: {
          explainerIconName: 'icon-bulb',
          explainerTitle: 'Why Do This?',
          explainerText: `
                        <br/>
                        <p>The reminder email before due date is used to:</p>
                        <br/>
                        <ul class='list-disc pl-4'>
                            <li class='py-1'>confirm that the invoice is not questioned by the customer;</li>
                            <li class='py-1'>remember that you expect payment on date;</li>
                        </ul>    
                    `,
          subject: 'Your invoice number {{invoiceNumber}} from {{senderCompanyName}} is due soon',
          itemBody: {
            title: 'REMINDER BEFORE DEADLINE'
          }
        },
        ReminderJustAfterDueDate: {
          explainerIconName: 'icon-bulb',
          explainerTitle: 'Why Do This?',
          explainerText: `
                        <br/>
                        <p>It is important to follow up with your customer quickly after the contractual payment date to inform him that the date has passed and that he must perform. </p>
                        <br/>
                        <p>The sooner you follow up with your client, the sooner you will receive payment!</p>
                        <br/>
                        <p>When you want to get paid, rigor and speed are precious allies.</p>
                        <br/>
                    `,
          subject: 'Your invoice number {{invoiceNumber}} from {{senderCompanyName}} is pending payment',
          itemBody: {
            title: 'AWAITING PAYMENT',
            thanksForPaying: 'Please pay now using the payment methods below.'
          }
        },
        ReminderShortlyAfterDueDate: {
          explainerIconName: 'icon-bulb',
          explainerTitle: 'Why Do This?',
          explainerText: `
                        <br/>
                        <p>The first email often goes by the wayside. It's like that. We therefore send this friendly reminder to restore urgency to your client.</p>
                        <br/>
                        <p>Often, this second email is the necessary reminder for your customer to decide to manage the matter of your payment :)</p>
                        <br/>
                    `,
          subject: 'Your invoice number {{invoiceNumber}} from {{senderCompanyName}} is pending payment',
          itemBody: {
            title: 'AWAITING PAYMENT',
            waitFor: 'I hope you are well. Despite my last letter, I have not received payment for the ',
            ifNotMistaken: 'Unless we are mistaken, we have not received payment for the',
            thanksForPaying: 'Please pay now using the payment methods below.',
            ifYouFind: 'If you are having difficulty paying this invoice, please contact {{senderFirstName}} {{senderLastName}}, either by email {{senderEmail}} or by phone at the following number: {{senderPhoneNumber }}.'
          }
        },
        DirectReminderSomeTimeAfterDueDate: {
          explainerIconName: 'icon-bulb',
          explainerTitle: 'Why Do This?',
          explainerText: `
                        <br/>
                        <p>To continue to put pressure on your debtor to obtain payment of the amount owed from him. </p><br/>
                        <p>In addition, if you have to start legal proceedings, it is important to prove your good faith. </p><br/>
                        <p>How?  By showing that you have tried all out-of-court collection procedures first.</p><br/>
                        <p>Keep a calm voice, stay collected (pun intended) but firm. Reiterate your demand for a specific date.</p><br/>
                      `,
          subject: '',
          itemBody: `
                        <br/>
                        <p>You: Hello, this is {{senderName}} {{senderLastName}}. How are you?</p><br/>
                        <p>Client: Good. How are you?</p><br/>
                        <p>You: I’m good, thank you. How is business going? <Here insert a fact about their business> Did you get the contract you were hoping for?</p><br/>
                        <p>Client:...</p><br/>
                        <p>You: The reason I’m calling I was going through my files and I’m showing an outstanding invoice that was due {{dueDate}}.</p><br/>
                        <p>Client:...</p><br/>
                        <p>You: Do you know when it will be paid?</p><br/>
                        <p>You: On <date>? That sounds great. I will be sure to check back then.</p><br/>
                      `
        },
        /*StrongLastReminderAfterDueDate:       {
                                                                                                            timelineState: "Reminder to be sent",
                                                                                                            timelineDoneState: "Last Reminder sent to customer",
                                                                                                            timelineDoneCopy: "Sent {{dateDone}}",
                                                                                                            explainerIconName: 'icon-bulb',
                                                                                                            explainerTitle: 'Why Do This?',
                                                                                                            explainerText: `
                                                                                                                <br/>
                                                                                                                <p>The letter of formal notice is the last step before litigation with the client.</p><br/>
                                                                                                                <p>It is usually a good idea to lawyer up in case your client does not pay.</p><br/>
                                                                                                                <p>The formal language combined with the letterhead usually works wonders.</p><br/>
                                                                                                                <p>It also allows you to officially add late payment fees. It is the ultimate leverage to make your customer pay.</p><br/>
                                                                                                              `, subject: 'Invoice {{invoiceNumber}} is overdue - Please send payment ASAP', itemBody: `
                                                                                                                <p>Hi {{firstName}},</p><br/>
                                                                                                                <p>I wrote to you several times to remind you of the pending amount of {{invoiceAmount}} for invoice {{invoiceNumber}}. As another reminder, payment was due {{dueDays}} days ago.</p><br/>
                                                                                                                <p>Please be aware that, as per the terms of the invoice, you may be charged additional interest on payment received more than 30 days past its due date.</p><br/>
                                                                                                                <p>If you have any queries regarding this payment, please let me know. I’ve also attached a copy of the invoice to this email, in case the original was lost or deleted.</p><br/>
                                                                                                                <p>Could you please reply to this message and let me know you’ve received it?</p><br/>
                                                                                                                <p>Thank you.</p><br/>
                                                                                                                <p>Regards,</p>
                                                                                                                <p>{{senderName}}</p>
                                                                                                              `
                                                                                                        },*/
        StrongLastReminderAfterDueDate: {
          downloadLetter: 'Download PDF Letter',
          downloadLetterPrefix: 'Formal-Notice',
          explainerIconName: 'icon-bulb',
          explainerTitle: 'Why Do This?',
          explainerText: `
                        <br/>
                        <p>The letter of formal notice is the last step before litigation with the client.</p><br/>
                        <p>It is usually a good idea to lawyer up in case your client does not pay.</p><br/>
                        <p>The formal language combined with the letterhead usually works wonders.</p><br/>
                        <p>It also allows you to officially add late payment fees. It is the ultimate leverage to make your customer pay.</p><br/>
                      `,
          subject: '',
          intendedFor: 'Addressed to',
          letterOnTime: ', ',
          itemBody: `
                        <p>Dear [Mr/Ms] {{firstName}} {{lastName}},</p><br/>
                        <p>We refer to your overdue account full details of which have previously been supplied to you in writing. 
                        We note that the amount of {{invoiceAmount}} is currently outstanding and payment has not been made in respect of your 
                        account in over {{dueDays}} days, despite our repeated reminders.</p><br/>
                        <p>We wish to inform you that we have a strict policy of pursuing all debts owed to us.  
                        Accordingly if payment is not made in respect of the above account within TEN (10) DAYS of the date of this letter 
                        we will be forced to instruct our solicitors to initiate legal proceedings to recover the debt owed by you.</p><br/>
                        <p>Please note that in the event that we instruct our solicitors initiate legal proceedings against you an application 
                        will also be made to the court to have you fixed with the legal costs of these proceedings along with any other fees which 
                        are necessary and incidental to the same, including any barristers’ fees, court stamp duty and any other associated costs.</p><br/>
                        <p>If we do not receive your payment in the amount of €[Insert Amount] within TEN DAYS of the date of this letter we will instruct 
                        our solicitors to commence legal proceedings against you immediately without further notice.</p><br/>
                        <p>Please give this matter your immediate attention and contact us immediately to settle this outstanding debt in order to avoid legal 
                        action being commenced against you without further notice.</p><br/>
                        <p>Yours faithfully,</p><br/>
                        <p>{{senderName}} {{senderLastName}}</p><br/>
                      `
        },
        CollectionLitigation: {
          explainerIconName: 'icon-bulb',
          explainerTitle: 'Why Do This?',
          explainerText: `
                        <br/>
                        <p>If you have exhausted all amicable venues to recover your debt from your client, more drastic actions are in order.</p><br/>
                        <p>The amount of the debt, the legal and financial situation of your debtor, the disputes raised are all elements to be taken into account before initiating the most effective legal procedure.</p><br/>
                      `,
          subject: '',
          itemBody: `
                       <br/>
                       <p>If you decide to take legal action, you first need to send a formal demand letter to the defendant, whether it be an individual or a business.</p><br/>
                       <p>In your demand letter, you need to:</p><br/>
                       <!--TODO: fix the styles here.-->
                       <ul>
                           <li> &nbsp;&nbsp;&bull;&nbsp; Clearly identify your customer’s faults</li>
                           <li> &nbsp;&nbsp;&bull;&nbsp; State how much is owed;</li>
                           <li> &nbsp;&nbsp;&bull;&nbsp; Demand payment of all past due amounts by a certain date; and</li>
                           <li> &nbsp;&nbsp;&bull;&nbsp; Advise of possible legal action.</li>
                       </ul><br/>
                       <p>The next step is to file and serve a complaint in a court with the proper jurisdiction. Typically this will be in the county where the transactions took place or where the defendant resides/operates.</p><br/>
                       <p>After you file a complaint, there are ways to seek relief early on in the process. For example, you could apply for a writ of attachment or writ of possession to either place a lien on some of the defendant's assets or to take possession of them (if they were collateral for your agreement). This can protect your ability to collect on a judgment while also pressuring the defendant to settle the case.</p><br/>
                      `
        },
        DisputeResolution: {
          explainerIconName: 'icon-bulb',
          explainerTitle: 'Why Do This?',
          explainerText: `
                        <br/>
                        <p>In case of a commercial dispute, the first step is to phone your client.</p><br/>
                        <ul>
                            <li> &nbsp;&nbsp;&bull;&nbsp; Review what was agreed, and how clear the agreement was.</li>
                            <li> &nbsp;&nbsp;&bull;&nbsp; Collate evidence (eg: approved order, delivery note, written contracts, correspondence and witness statements).</li>
                            <li> &nbsp;&nbsp;&bull;&nbsp; Try to negotiate an amicable resolution. Keep evidence of negotiations including copies of letters and notes on conversations.</li>
                            <li> &nbsp;&nbsp;&bull;&nbsp; Follow any alternative dispute resolution processes outlined in your contract before pursuing legal recourse.</li>
                        </ul>
                      `,
          subject: '',
          itemBody: `
                     TODO
                  `
        }
      },
      email: {
        content: {
          siren: 'SIREN : {{senderSiren}}'
        },
        footer: {
          title: 'Get paid easier & faster.',
          content: 'You received this email because your supplier {{senderName}} uses Dundy to track and follow up their invoices for free. • <a href="https://www.dundy.co/" style="text-decoration:underline;">What\'s Dundy?</a>'
        },
        toast: {
          emailGenerationFail: 'Error generating email'
        }
      }
    },
    cashApplication: {
      modal: {
        title: 'Cash Application on this transaction',
        subtitle: 'Link one or more invoices to this transaction. This action automatically stops the dunning of the selected invoices.',
        controls: {
          cancel: 'Cancel',
          save: 'Save',
          savingInProgress: 'Saving in progress...'
        }
      },
      feedbacks: {
        successfullySavedCashApplication: 'Transaction successfully cash applied'
      },
      missingBankAccount: {
        notOwner: 'Please contact the administrator of this account to add a bank account.'
      },
      cashApplicationDeclaredRow: {
        removeLink: 'Remove link'
      },
      cashApplicationSidebarTitle: 'Transaction',
      cashApplicationTitle: 'Cash Application',
      appliedInvoices: {
        title: 'Applied invoice(s)',
        tooltip: 'Add invoices'
      },
      allocatedAmount: 'Allocated amount:',
      currencySymbols: {
        EUR: 'EUR'
      },
      leftToAllocate: 'Left to allocate:',
      controls: {
        edit: 'Edit'
      },
      status: {
        toCashApply: 'Awaiting Application',
        partiallyCashApply: 'Partially Applied',
        cashApplied: 'Fully Applied'
      }
    },
    payments: {
      heading: {
        one_one: 'Confirm {{count}} new payment!',
        one_other: 'Confirm {{count}} new payments!'
      },
      empty: {
        title: 'No new payments',
        first_paragraph: 'You don’t have any new payments to approve.',
        second_paragraph: 'We will let you know when anything comes in.'
      },
      table: {
        transactionHeading: 'Transactions',
        transactionSub: 'The transactions below have been linked to pending invoices',
        invoicesHeading: 'Outstanding Invoices',
        invoicesSub: 'Below is the list of invoices our system linked to incoming payments',
        time: {
          due_one: 'due in {{count}} day',
          due_other: 'due in {{count}} days',
          overdue_one: 'overdue by {{count}} day',
          overdue_other: 'overdue by {{count}} days'
        }
      },
      buttons: {
        confirm: 'Confirm payment',
        cancel: 'Cancel link'
      }
    },
    paymentPortal: {
      paymentCompleted: {
        title: 'Payment successful!',
        copy: 'A payment of',
        copytwo: 'was made to',
        emailText: 'An email detailing the transaction has also been sent to your inbox.'
      },
      selectBank: {
        bank: 'Type your bank name',
        error: 'Selection failed.',
        title: 'Choose your bank account',
        copy: 'Start by typing the name of the bank',
        copytwo: 'you wish to use for your payment',
        continue: 'PROCEED WITH THIS BANK'
      },
      viewPayment: {
        copy: 'has sent you the following',
        copytwo: 'payment request.',
        invoiceLabel: 'Invoice',
        dueLabel: 'Due',
        instantTransfer: 'Instant Bank Transfer',
        instantTransferCopy: 'Initiate a wire transfer in a few seconds.',
        note: 'Your payment information will remain secure and ',
        notetwo: 'hidden from your client and any other third parties.',
        continue: 'PAY'
      },
      reviewPayment: {
        title: 'Review your payment',
        titletwo: 'to',
        editLink: 'EDIT',
        totalLabel: 'Total',
        totalNote: '**Free transaction. No fees will be applied.',
        invoiceLabel: 'Invoice',
        dueLabel: 'Due',
        paymentDateLabel: 'Payment date',
        note: 'Payment Secured by',
        continue: 'CONFIRM PAYMENT TO'
      }
    },
    exports: {
      pageTitle: 'Exports',
      fec: 'FEC file',
      zip: 'ZIP file',
      errorDate: 'Start date must be greater than end date',
      errorCheck: 'At least one box must be checked',
      toastEmpty: 'No data available for these parameters'
    },
    template: {
      countDescription_one: '{{count}} template',
      countDescription_more: '{{count}} templates',
      languages: 'Languages',
      fallbackLanguage: 'Fallback language',
      defaultLanguage: 'Default language',
      title: 'Mail templates',
      search: 'Search for a template by name',
      add: 'Add a new template',
      edit: 'Edit this template',
      duplicate: 'Duplicate this template',
      delete: 'Delete this template',
      name: 'Name',
      actions: 'Actions',
      deleteHeading: 'Confirm deletion?',
      deletePrimaryButton: 'Delete',
      deleteSecondaryButton: 'Cancel',
      deleteInfo: 'Do you really want to delete this template? All data will be permanently deleted from our servers. This action cannot be undone.',
      duplicateHeading: 'Duplicate template?',
      duplicatePrimaryButton: 'Duplicate',
      duplicateSecondaryButton: 'Cancel',
      duplicateInfo: 'To duplicate the template, enter a name:',
      duplicateError: 'A template already exists with this name',
      duplicatePlaceholder: 'Model name',
      createFeedback: 'The template has been created',
      updateFeedback: 'The template has been updated',
      duplicateFeedback: 'The template has been duplicated',
      deleteFeedback: 'The template has been deleted',
      missPropertiesFeedback: 'Fields are empty',
      existingTemplateFeedback: 'A template already exists with this name',
      mediaKind: 'Reminder type',
      manual: 'Manuel',
      mail: 'Email',
      modal: {
        title: 'Edit model',
        name: 'Name',
        from: 'From',
        to: 'TO',
        cc: 'CC',
        cci: 'CCI',
        attentionOf: 'For the attention of',
        mailTo: 'Copy by mail',
        escalation: 'Escalade',
        subject: 'Subject',
        phone: 'Telephone',
        save: 'Save model',
        saved: 'Model saved successfully',
        cancel: 'Cancel',
        edit: 'Edit',
        preview: 'Preview',
        editSystemDefault: 'Edit default template',
        breadcrumbWorkspace: 'Global template',
        breadcrumbCustomer: 'Customer template - {{name}}'
      },
      variable: {
        show: 'Display variables',
        hide: 'Hide variables',
        title: 'Variables',
        filter: 'Search for a variable',
        keywords: {
          myEmailSignature: 'My signature',
          bankTransferBlock: 'Bank transfer block',
          paymentLinkBlock: 'Payment link block',
          invoiceNumber: 'Invoice number',
          invoiceAmount: 'Invoice amount',
          invoiceRemainingAmount: 'Remaining amount',
          invoiceDueDate: 'Due date',
          invoiceIssueDate: 'Issue date',
          firstCustomerContact: 'First customer contact',
          firstCustomerLastName: 'Customer contact\'s last name',
          firstCustomerFullName: 'Customer contact\'s full name',
          customerCompanyName: 'Customer\'s company name',
          customerCompanyAddress: 'Customer\'s company address',
          myFirstName: 'My first name',
          myLastName: 'My last name',
          myFullName: 'My full name',
          myMobileNumber: 'My mobile number',
          myOfficeNumber: 'My office phone number',
          myPhoneNumbers: 'My phone numbers',
          myEmailAddress: 'My email',
          myCompanyName: 'My company name',
          myCompanyAddress: 'My company\'s address'
        }
      },
      errors: {
        missingMail: 'Missing mail',
        onlyOneMail: 'Only one mail is allowed',
        emptyField: 'Empty field',
        badValues: 'Unaccepted : {{error}}'
      }
    },
    bankWall: {
      title: 'Be more efficient!',
      baseline: 'Unlock more features immediately, by connecting your bank account for free.',
      payTitle: 'Get paid 3x faster',
      payBaseline: 'The payment link makes your customer\'s life easier. And yours.',
      reconcilingTitle: 'Reconcile payments',
      reconcilingBaseline: 'View all your customer payments and link them in one click.',
      realtimeTitle: 'Visualize your situation',
      realtimeBaseline: 'Get a bird\'s eye view of your accounts receivable in real time.',
      button: 'Connect my bank account for free',
      deprecated: 'The authorization to connect to your bank account is no longer valid. Please reconnect it to take full advantage of the features again',
      security: 'We use your banking data exclusively for the functionalities described above.<br>They are never sold or communicated to third parties. Learn more about <a href="https://www.dundy.co/manifeste" target="_blank">our commitments relating to your banking data.</a>'
    }, 
    grid: {
      edit: 'Edit',
      duplicate: 'Duplicate',
      delete: 'Delete',

      empty: 'To begin, click on the “Add” button',
      deleteHeading: 'Confirm deletion?',
      deleteContent: 'All data will be permanently deleted from our servers. This action cannot be undone.',
      duplicateHeading: 'Confirm copy?',
      duplicateContent: 'To duplicate, enter another name:',
      duplicatePlaceholder: 'Copy name',
      duplicateError: 'Name already exists'
    },
    workflow: {
      title: 'Dunning plan',
      subtitle: 'Manage the steps and content of your dunning',
      issueDate: 'Issue Date',
      issueDateDesc: 'TODO',
      issueDatePlus7: 'Issue Date + 7 days',
      dueDateLess6: 'Due Date - 6 days',
      dueDate: 'Due Date',
      dueDatePlus2: 'Due Date + 2 days',
      dueDatePlus6: 'Due Date + 6 days',
      dueDatePlus15: 'Due Date + 15 days',
      dueDatePlus20: 'Due Date + 20 days',
      dueDatePlus35: 'Due Date + 35 days',
      defaultTemplate: 'Default template',
      customTemplate: 'Custom template',
      manual: 'Manual',
      auto: 'Auto.',
      editItem: 'Edit workflow item',
      step: 'Step ',
      advancedMode: 'Advanced mode'
    },
    actions: {
      add: 'Add',
      cancel: 'Cancel',
      create: 'Create',
      delete: 'Delete',
      duplicate: 'Duplicate',
      edit: 'Edit',
      save: 'Save',
      update: 'Update',
      new: 'New',
      on: 'On',
      off: 'Off',
      enable: 'Enable',
      disable: 'Disable',
      preview: 'Preview'
    }
  }
}
