<script lang="ts">
  import { onMount } from 'svelte'
  import { navigate, Route, Router } from 'svelte-routing'
  import Today from './Dashboard.svelte'
  import Settings from './Settings.svelte'
  import { checkSignInOrRedirect } from '../services/guard'
  import Customers from '../../crm-app/pages/Customers.svelte'
  import Invoices from '../../voxy-app/pages/Invoices.svelte'
  import Team from '../../crm-app/pages/Team.svelte'
  import ReceivableAction from '../../dundy-app/pages/ReceivableAction.svelte'
  import Workspaces from '../../crm-app/pages/Workspaces.svelte'
  import VoxyInvoiceAddModal from '../../voxy-app/pages/VoxyInvoiceAddModal.svelte'
  import ExternalInvoiceAddModal from '../../dundy-app/pages/ExternalInvoiceAddModal.svelte'
  import SelectWorkspace from '../../crm-app/pages/workspace/SelectWorkspace.svelte'
  import VoxyInvoiceViewFullModal from '../../voxy-app/pages/VoxyInvoiceViewFullModal.svelte'
  import DundyInvoiceViewFullModal from '../../dundy-app/pages/DundyInvoiceViewFullModal.svelte'
  import { updateNewNavigationHistoryItem } from '../stores/navigationHistory.store'
  import {
    navigationToHistoryItem,
    previousNavigationHistoryItem
  } from '../stores/navigationHistory.store.js'
  import Transactions from '../../bank-app/pages/Transactions.svelte'
  import NewWorkspaceAddModal from '../../crm-app/pages/NewWorkspaceAddModal.svelte'
  import Onboarding from './Onboarding.svelte'
  import NoZoneOK from './NoZoneOK.svelte'
  import Dunning from '../../dundy-app/pages/Dunning.svelte'
  import Quotes from '../../kotly-app/pages/Quotes.svelte'
  import CashApplicationModal from '../../cash-application-app/pages/CashApplicationModal.svelte'
  import KotlyQuoteAddModal from '../../kotly-app/pages/KotlyQuoteAddModal.svelte'
  import TransactionsFileUpload from '../../bank-app/pages/TransactionsFileUpload.svelte'
  import type { NavigationHistoryEvent } from '../models/navigation-history-item'
  import { featureToggling } from '$config/feature-toggling'
  import Template from '$src/dundy-app/pages/Template.svelte'
  import Sidebar from '$core/lib/layout/Sidebar.svelte'
  import TopBar from '$core/lib/layout/TopBar.svelte'
  import mixpanel from 'mixpanel-browser'
  import Workflow from '$src/dundy-app/pages/Workflow.svelte'
  import Templates from '$src/dundy-app/pages/Templates.svelte'
  import TodoAction from '$src/dundy-app/pages/TodoAction.svelte'

  const prodEnv = process.env.APP_ENV === 'prod remote'
    
  /**
   * This function is used to close a modal
   * and navigate back to the previous page.
   * @param returnToPathOnCloseEvent
   */
  const closeWithProperNavigationHistoryHandling = (returnToPathOnCloseEvent: NavigationHistoryEvent) => {
    const returnToPathOnCloseHere = returnToPathOnCloseEvent.detail
    /* console.log('ccc navigate returnToPathOnClose', returnToPathOnCloseHere.relativeURL) */
    navigationToHistoryItem(returnToPathOnCloseHere)
  }

  onMount(() => {
    updateNewNavigationHistoryItem('Home.svelte')
    checkSignInOrRedirect()
    // TODO correcting routing issue here - but such parallel routing logics is bad practice: to be moved or deleted to both prevent empty dashboard to be visible when signing-in (before choosing a workspace) and with signing-out + prevent intermediate 404 when transitioning from signin-in page to workspace selection
    if (window.location.pathname === '/') {
      /* console.warn('redirecting / to /dashboard') */
      navigate('/today')
    }

    if (localStorage.getItem('onboarding')) {
      localStorage.removeItem('onboarding')
      mixpanel.track('onb.end.E00.u onboarding end to dashboard', {
        'Description': 'Last step, user is on dashboard. Referrer is Company-Info'
      })
    }
  })
</script>

<svelte:head>
  <style lang="postcss">
    body {
      @apply bg-almostWhite;
    }
  </style>
</svelte:head>

<div>
  <Sidebar/>

  <div class="lg:pl-72">
    <TopBar />

    <main class="py-6">
      <div class="relative px-4 sm:px-6 lg:px-8">
        <Router>
          <Route path="action/:workspaceId/:customerId/:invoiceId/*" let:params>
            <ReceivableAction workspaceId={params.workspaceId} customerId={params.customerId}
                              invoiceId={params.invoiceId}/>
          </Route>

          <Route path="action/:workspaceId/:customerId/:invoiceId/todo" let:params>
            <TodoAction customerId={params.customerId} invoiceId={params.invoiceId}/>
          </Route>

          <Route path="select-workspace">
            <SelectWorkspace/>
          </Route>

          {#if !prodEnv}
            <Route path="workspaces">
              <Workspaces/>
            </Route>
            <Route path="workspaces/new">
              <NewWorkspaceAddModal
                on:close={() => navigate('/workspaces')}
              />
            </Route>
          {/if}

          <Route path="today">
            <Today/>
          </Route>
          <Route path="onboarding/*">
            <Onboarding/>
          </Route>
          <Route path="transactions/*">
            <Transactions/>
          </Route>
          <Route path="cash-application/view/:id/*" let:params>
            <CashApplicationModal
              transactionId="{params.id}"
              on:close={() => {navigate('/transactions')}}
            />
          </Route>

          {#if (featureToggling().isKotlyEnabled)}
            <Route path="quotes/*">
              <Quotes/>
            </Route>
            <Route path="quotes/new/*">
              <KotlyQuoteAddModal
                on:close={() => { navigate('/quotes')}}/>
            </Route>
            <Route path="quotes/view/:id/*" let:params>
              <KotlyQuoteAddModal
                businessDocumentId={params.id}
                on:close={() => { navigate('/quotes')}}/>
            </Route>
          {/if}

          <Route path="invoices/*">
            <Invoices/>
          </Route>
          <Route path="dunning/*">
            <Dunning/>
          </Route>
          <Route path="receivables/external/view/:id/*" let:params>
            <DundyInvoiceViewFullModal
              invoiceId={params.id}
              returnToPathOnClose={previousNavigationHistoryItem('Home.svelte')}
              on:close={closeWithProperNavigationHistoryHandling}
            />
          </Route>
          <Route path="receivables/view/:id/*" let:params>
            <VoxyInvoiceViewFullModal
              businessDocumentId={params.id}
              returnToPathOnClose={{ 'relativeURL': '/invoices' }}
              on:close={closeWithProperNavigationHistoryHandling}
            />
          </Route>
          <Route path="receivables/new/:businessDocumentSlug/*" let:params>
            <VoxyInvoiceAddModal
              invoiceId=""
              businessDocumentSlug={params.businessDocumentSlug}
              on:close={() => {
                /* console.log('ccc on:close VoxyInvoiceAddModal.svelte in Home') */
                /* console.log('navigate in', 'VoxyInvoiceAddModal.svelte Route path="receivables/new/*" on:close()', ': at window.location.pathname=', window.location.pathname, 'nextNavigationURI=', '/invoices') */
                navigate('/invoices')
              }}
              on:saved={(e) => navigate(`/invoices?saved=${e.detail.type}`) }
            />
          </Route>
          <Route path="receivables/edit/:id/*" let:params>
            <VoxyInvoiceAddModal
              businessDocumentSlug={params.businessDocumentSlug}
              invoiceId={params.id}
              on:close={() => {
                navigate('/invoices')
              }}
              on:saved={(e) => {
                navigate(`/invoices?saved=${e.detail.type}`)
              } }
            />
          </Route>
          <Route path="receivables/external/new">
            <ExternalInvoiceAddModal
              on:close={() =>
                navigate('/dunning')
              }
            />
          </Route>
          <Route path="receivables/external/edit/:id" let:params>
            <ExternalInvoiceAddModal
              invoiceId={params.id}
              on:close={() =>
              /* console.log('navigate in', 'ExternalInvoiceAddModal.svelte Route path="receivables/external/edit/:id" on:close()', ': at window.location.pathname=', window.location.pathname, 'nextNavigationURI=', '/dunning') */

                navigate('/dunning')
                        }
            />
          </Route>
          <Route path="clients/*">
            <Customers/>
          </Route>
          {#if !prodEnv}
            <Route path="team/*">
              <Team/>
            </Route>
          {/if}
          <Route path="settings/*">
            <Settings/>
          </Route>
          <Route path="/transactions-file-upload">
            <TransactionsFileUpload/>
          </Route>
          <Route path="template/*">
            <Template/>
          </Route>
          <Route path="templates/*">
            <Templates />
          </Route>
          <Route path="workflow/*">
            <Workflow/>
          </Route>
          <!-- fallback route -->
          <Route>
            <NoZoneOK/>
          </Route>
        </Router>
      </div>
    </main>
  </div>
</div>